import * as Yup from "yup";

export const loginValidationSchema = Yup.object({
  userName: Yup.string()
    .min(3, "userName must be at least 3 characters long")
    .required("User Unique Id is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters long")
    .required("Password is required"),
});
