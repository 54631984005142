import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  FetchSchoolGroupResponse,
  FetchSchoolsResponse,
  SystemAdminState,
  SchoolGroup,
  SchoolAdmin,
  Schools,
  FeeCategorySchool,
  RemainderTypes,
  DashboardData,
  RemainderSettingsTypes,
  RemainderSettingsTypesByTd,
  SchoolGroupAdmin,
} from "./systemAdminType";
import {
  fetchSchoolGroup,
  fetchSchoolByGroupId,
  createSchoolGroup,
  updateSchoolGroup,
  createSchoolInGroup,
  updateSchoolInGroup,
  uploadMultipleFiles,
  createschoolAdmin,
  fetchSchooladmin,
  fetchReminderTypes,
  updateSchooladmin,
  fetchFeeCategoryOfSchool,
  createFeeCategoryOfSchool,
  updateFeeCategoryOfSchool,
  deleteFeeCategoryOfSchool,
  createReminderTypes,
  updateReminderTypes,
  deleteReminderTypes,
  fetchSystemAdminDashboardData,
  fetchReminderSettingOfSchool,
  createReminderSettingOfSchool,
  updateReminderSettingOfSchool,
  deleteReminderSettingOfSchool,
  fetchReminderSettingOfSchoolById,
  createSchoolGroupAdmin,
  fetchAllSchoolAdmin,
  updateSchoolGroupAdmin,
} from "./systemAdminApi";
import { errorToast, successToast } from "../../../../common/toast/toast";

const initialState: SystemAdminState = {
  systemAdminData: [],
  remainderTypeData: [],
  dashboardData: {
    totalSchoolGroups: 0,
    totalSchools: 0,
    totalStudents: 0,
    schools: [],
  },
  remainderSettingsByIdData: {
    id: 0,
    schoolId: 0,
    reminderTypeId: 0,
    toCCBCCSettings: {
      ccSetting: {
        other: [],
        parent: false,
        student: false,
        teacher: false,
        schoolAdmin: false,
      },
      toSetting: {
        other: [],
        parent: false,
        student: false,
        teacher: false,
        schoolAdmin: false,
      },
      bccSetting: {
        other: [],
        parent: false,
        student: false,
        teacher: false,
        schoolAdmin: false,
      },
    },
    frequency: [],
    isActive: false,
  },
  remainderSettingsData: [],
  schoolList: [],
  schoolgroupAdminData: [],
  schoolGroupData: [],
  feeCategorySchoolData: [],
  schoolAdminData: [],
  globalAcademicYearId: null,
  fetchLoading: false,
  loading: false,
  error: null,
};

export const uploadMultipleFilesAsync = createAsyncThunk(
  "utils/uploadMultipleFiles",
  async (
    { formData, fileCategory }: { formData: FormData; fileCategory: string },
    thunkAPI
  ) => {
    try {
      const response = await uploadMultipleFiles(formData, fileCategory);
      return response?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to upload file"
      );
    }
  }
);

export const fetchSchoolGroupAsync = createAsyncThunk(
  "systemAdmin/fetchSchoolGroup",
  async (_, thunkAPI) => {
    try {
      return await fetchSchoolGroup();
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch Groups"
      );
    }
  }
);

export const fetchSchoolByGroupIdAsync = createAsyncThunk<
  FetchSchoolsResponse,
  number
>("academicYear/fetchSchoolByGroupId", async (id) => {
  return await fetchSchoolByGroupId(id);
});

export const createSchoolGroupAsync = createAsyncThunk(
  "schoolGroup/createSchoolGroup",
  async ({ newSchoolGroup }: { newSchoolGroup: SchoolGroup }, thunkAPI) => {
    try {
      return await createSchoolGroup(newSchoolGroup);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to create Groups"
      );
    }
  }
);
export const updateSchoolGroupAsync = createAsyncThunk(
  "schoolGroup/updateSchoolGroup",
  async (
    {
      groupId,
      updatedSchoolGroup,
    }: { groupId: number; updatedSchoolGroup: SchoolGroup },
    thunkAPI
  ) => {
    try {
      return await updateSchoolGroup(groupId, updatedSchoolGroup);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to update Groups"
      );
    }
  }
);

export const createSchoolInGroupAsync = createAsyncThunk(
  "schoolGroup/createSchoolInGroup",
  async (
    {
      groupId,
      newSchoolInGroup,
    }: { groupId: number; newSchoolInGroup: Schools },
    thunkAPI
  ) => {
    try {
      return await createSchoolInGroup(groupId, newSchoolInGroup);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to create Groups"
      );
    }
  }
);

export const updateSchoolInGroupAsync = createAsyncThunk(
  "schoolGroup/updateSchoolInGroup",
  async (
    {
      groupId,
      id,
      updatedSchoolGroup,
    }: { groupId: number; id: number; updatedSchoolGroup: Schools },
    thunkAPI
  ) => {
    try {
      return await updateSchoolInGroup(groupId, id, updatedSchoolGroup);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to update Groups"
      );
    }
  }
);

export const createschoolAdminAsync = createAsyncThunk(
  "schoolGroup/createschoolAdmin",
  async (
    {
      schoolId,
      newSchoolAdmin,
    }: { schoolId: number; newSchoolAdmin: SchoolAdmin },
    thunkAPI
  ) => {
    try {
      return await createschoolAdmin(schoolId, newSchoolAdmin);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to create Groups"
      );
    }
  }
);

// export const fetchSchooladminAsync = createAsyncThunk<SchoolAdmin>(
//   "schoolGroup/fetchSchooladmin",
//   async () => {
//     return await fetchSchooladmin();
//   }
// );

export const fetchSchooladminAsync = createAsyncThunk(
  "schoolGroup/fetchSchooladmin",
  async (_, thunkAPI) => {
    try {
      const response = await fetchSchooladmin();
      return response?.schoolAdmin;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to Fetch Fee Admin"
      );
    }
  }
);

export const updateSchooladminAsync = createAsyncThunk(
  "schoolGroup/updateSchooladmin",
  async (
    {
      schoolId,
      schoolAdminId,
      updateSchoolAdmin,
    }: {
      schoolId: number;
      schoolAdminId: number;
      updateSchoolAdmin: SchoolAdmin;
    },
    thunkAPI
  ) => {
    try {
      return await updateSchooladmin(
        schoolId,
        schoolAdminId,
        updateSchoolAdmin
      );
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to create Groups"
      );
    }
  }
);

export const fetchFeeCategoryOfSchoolAsync = createAsyncThunk(
  "feecategory/fetchFeeCategoryOfSchool",
  async ({ schoolId }: { schoolId: number }, thunkAPI) => {
    try {
      const response = await fetchFeeCategoryOfSchool(schoolId);
      return response?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to Fetch Fee Categories"
      );
    }
  }
);

export const createFeeCategoryOfSchoolAsync = createAsyncThunk(
  "feecategory/createFeeCategoryOfSchool",
  async (
    {
      schoolId,
      createfeecategory,
    }: { schoolId: number; createfeecategory: FeeCategorySchool },
    thunkAPI
  ) => {
    try {
      return await createFeeCategoryOfSchool(schoolId, createfeecategory);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to create Groups"
      );
    }
  }
);

export const updateFeeCategoryOfSchoolAsync = createAsyncThunk(
  "feecategory/updateFeeCategoryOfSchool",
  async (
    {
      schoolId,
      feecategoryId,
      updatefeecategory,
    }: {
      schoolId: number;
      feecategoryId: number;
      updatefeecategory: FeeCategorySchool;
    },
    thunkAPI
  ) => {
    try {
      return await updateFeeCategoryOfSchool(
        schoolId,
        feecategoryId,
        updatefeecategory
      );
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to create Groups"
      );
    }
  }
);

export const deleteFeeCategoryOfSchoolAsync = createAsyncThunk(
  "feecategory/deleteFeeCategoryOfSchool",
  async (
    { schoolId, feeCategoryId }: { schoolId: number; feeCategoryId: number },
    thunkAPI
  ) => {
    try {
      await deleteFeeCategoryOfSchool(schoolId, feeCategoryId);
      return { schoolId, feeCategoryId };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to delete class"
      );
    }
  }
);

export const fetchReminderTypesAsync = createAsyncThunk(
  "reminder/fetchReminderTypes",
  async (_, thunkAPI) => {
    try {
      const response = await fetchReminderTypes();
      return response?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to Fetch Remainder Types"
      );
    }
  }
);

export const createReminderTypesAsync = createAsyncThunk(
  "reminder/createReminderTypes",
  async (
    { createremaindertype }: { createremaindertype: RemainderTypes },
    thunkAPI
  ) => {
    try {
      return await createReminderTypes(createremaindertype);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to create Remainder Types"
      );
    }
  }
);

export const updateReminderTypesAsync = createAsyncThunk(
  "reminder/updateReminderTypes",
  async (
    {
      reminderTypeId,
      updateremaindertype,
    }: { reminderTypeId: number; updateremaindertype: RemainderTypes },
    thunkAPI
  ) => {
    try {
      return await updateReminderTypes(reminderTypeId, updateremaindertype);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to create Remainder Types"
      );
    }
  }
);

export const deleteReminderTypesAsync = createAsyncThunk(
  "reminder/deleteReminderTypes",
  async ({ reminderTypeId }: { reminderTypeId: number }, thunkAPI) => {
    try {
      await deleteReminderTypes(reminderTypeId);
      return { reminderTypeId };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to delete class"
      );
    }
  }
);

export const fetchReminderSettingOfSchoolAsync = createAsyncThunk(
  "reminder/fetchReminderSettingOfSchool",
  async ({ schoolId }: { schoolId: number }, thunkAPI) => {
    try {
      const response = await fetchReminderSettingOfSchool(schoolId);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch reminder settings"
      );
    }
  }
);

export const createReminderSettingOfSchoolAsync = createAsyncThunk(
  "reminder/createReminderSettingOfSchool",
  async (
    {
      schoolId,
      createremaindersettings,
    }: { schoolId: number; createremaindersettings: RemainderSettingsTypes },
    thunkAPI
  ) => {
    try {
      return await createReminderSettingOfSchool(
        schoolId,
        createremaindersettings
      );
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to create Remainder Settings"
      );
    }
  }
);

export const updateReminderSettingOfSchoolAsync = createAsyncThunk(
  "reminder/updateReminderSettingOfSchool",
  async (
    {
      id,
      schoolId,
      updateremaindersettings,
    }: {
      id: number;
      schoolId: number;
      updateremaindersettings: RemainderSettingsTypes;
    },
    thunkAPI
  ) => {
    try {
      return await updateReminderSettingOfSchool(
        schoolId,
        id,
        updateremaindersettings
      );
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to Update Remainder Settings"
      );
    }
  }
);

export const deleteReminderSettingOfSchoolAsync = createAsyncThunk(
  "reminder/deleteReminderSettingOfSchool",
  async (
    {
      schoolId,
      reminderSettingId,
    }: { schoolId: number; reminderSettingId: number },
    thunkAPI
  ) => {
    try {
      await deleteReminderSettingOfSchool(schoolId, reminderSettingId);
      return { reminderSettingId };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to delete Remainder Settings"
      );
    }
  }
);

export const fetchReminderSettingOfSchoolByIdAsync = createAsyncThunk(
  "reminder/fetchReminderSettingOfSchoolById",
  async (
    {
      schoolId,
      reminderSettingId,
    }: { schoolId: number; reminderSettingId: number },
    thunkAPI
  ) => {
    try {
      const response = await fetchReminderSettingOfSchoolById(
        schoolId,
        reminderSettingId
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch reminder settings"
      );
    }
  }
);

export const fetchDashboardDataAsync = createAsyncThunk<
  DashboardData,
  void,
  { rejectValue: string }
>("dashboard/fetchDashboardData", async (_, thunkAPI) => {
  try {
    const response = await fetchSystemAdminDashboardData();
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(
      error.response?.data?.message || "Failed to fetch dashboard data"
    );
  }
});

export const createSchoolGroupAdminAsync = createAsyncThunk(
  "schoolGroupAdmin/createSchoolGroupAdmin",
  async (
    {
      schoolGroupId,
      newSchoolGroupAdmin,
    }: { schoolGroupId: number; newSchoolGroupAdmin: SchoolGroupAdmin },
    thunkAPI
  ) => {
    try {
      return await createSchoolGroupAdmin(schoolGroupId, newSchoolGroupAdmin);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to create Groups"
      );
    }
  }
);

export const fetchAllSchoolAdminAsync = createAsyncThunk(
  "schoolGroupAdmin/fetchAllSchoolAdmin",
  async (_, thunkAPI) => {
    try {
      const response = await fetchAllSchoolAdmin();
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch Groups"
      );
    }
  }
);

export const updateSchoolGroupAdminAsync = createAsyncThunk(
  "schoolGroupAdmin/updateSchoolGroupAdmin",
  async (
    {
      schoolGroupAdminId,
      schoolGroupId,
      updatedSchoolGroupAdmin,
    }: {
      schoolGroupAdminId: number;
      schoolGroupId: number;
      updatedSchoolGroupAdmin: SchoolGroupAdmin;
    },
    thunkAPI
  ) => {
    try {
      return await updateSchoolGroupAdmin(
        schoolGroupId,
        schoolGroupAdminId,
        updatedSchoolGroupAdmin
      );
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to create Groups"
      );
    }
  }
);

const systemAdminSlice = createSlice({
  name: "systemAdmin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSchoolGroupAsync.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchSchoolGroupAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.schoolGroupData = action.payload.schoolGroup;
      })
      .addCase(fetchSchoolGroupAsync.rejected, (state, action) => {
        state.fetchLoading = false;
        state.error = action.error.message || "Failed to fetch school groups";
        errorToast(state.error);
      })
      .addCase(fetchSchoolByGroupIdAsync.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchSchoolByGroupIdAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.schoolList = action.payload.schoolList;
        console.log("Fetched schools:", action.payload);
      })
      .addCase(fetchSchoolByGroupIdAsync.rejected, (state, action) => {
        state.fetchLoading = false;
        state.error = (action.payload as string) || "Failed to fetch schools";
        errorToast(state.error);
      })
      // Group
      .addCase(createSchoolGroupAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSchoolGroupAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.schoolGroupData.push(action.payload.data);
        successToast(action.payload?.message);
      })
      .addCase(createSchoolGroupAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to create school group";
        errorToast(state.error);
      })
      .addCase(updateSchoolGroupAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSchoolGroupAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        successToast(action.payload.message);
      })
      .addCase(updateSchoolGroupAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to update school group";
        errorToast(state.error);
      })

      // schools In Groups

      .addCase(createSchoolInGroupAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSchoolInGroupAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.schoolList.push(action.payload.data);
        successToast(action.payload?.message);
      })
      .addCase(createSchoolInGroupAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to create school in group";
        errorToast(state.error);
      })

      //

      .addCase(updateSchoolInGroupAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSchoolInGroupAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        successToast(action.payload.message);
      })
      .addCase(updateSchoolInGroupAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to update school in group";
        errorToast(state.error);
      })

      .addCase(createschoolAdminAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createschoolAdminAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as string) || "Failed to create Admin";
        errorToast(state.error);
      })
      .addCase(createschoolAdminAsync.fulfilled, (state, action) => {
        state.schoolAdminData.push(action.payload.data);
        successToast(action.payload?.message);
      })

      .addCase(fetchSchooladminAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSchooladminAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch Admin";
        errorToast(state.error);
      })
      .addCase(fetchSchooladminAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.schoolAdminData = action.payload;
      })
      .addCase(updateSchooladminAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSchooladminAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to update School Admin";
        errorToast(state.error);
      })
      .addCase(updateSchooladminAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        successToast(action.payload.message);
      })
      .addCase(fetchFeeCategoryOfSchoolAsync.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchFeeCategoryOfSchoolAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.feeCategorySchoolData = action.payload;
        // successToast(action.payload.message);
      })
      .addCase(fetchFeeCategoryOfSchoolAsync.rejected, (state, action) => {
        state.fetchLoading = false;
        state.error =
          (action.payload as string) ||
          "Failed to fetch Fee Category of School";
        errorToast(state.error);
      })
      .addCase(createFeeCategoryOfSchoolAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createFeeCategoryOfSchoolAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) ||
          "Failed to create Fee Category of School";
        errorToast(state.error);
      })
      .addCase(createFeeCategoryOfSchoolAsync.fulfilled, (state, action) => {
        state.feeCategorySchoolData.push(action.payload.data);
        console.log(action, "action");
        successToast(action.payload.message);
      })
      .addCase(updateFeeCategoryOfSchoolAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateFeeCategoryOfSchoolAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        successToast(action.payload.message);
      })
      .addCase(updateFeeCategoryOfSchoolAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) ||
          "Failed to Update Fee Category of School";
        errorToast(state.error);
      })
      .addCase(deleteFeeCategoryOfSchoolAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteFeeCategoryOfSchoolAsync.fulfilled, (state, action) => {
        state.feeCategorySchoolData = state.feeCategorySchoolData.filter(
          (classItem) => classItem.id !== action.payload
        );
        successToast("Fee Category Delete Success");
      })
      .addCase(deleteFeeCategoryOfSchoolAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) ||
          "Failed to Delete Fee Category of School";
        errorToast(state.error);
      })
      .addCase(fetchReminderTypesAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchReminderTypesAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to fetch Remainder types";
        errorToast(state.error);
      })
      .addCase(fetchReminderTypesAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.remainderTypeData = action.payload;
      })
      .addCase(createReminderTypesAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createReminderTypesAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to create Remainder types";
        errorToast(state.error);
      })
      .addCase(createReminderTypesAsync.fulfilled, (state, action) => {
        state.remainderTypeData.push(action.payload.data);
        console.log(action, "action");
        successToast(action.payload.message);
      })
      .addCase(updateReminderTypesAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateReminderTypesAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        successToast("Remainder types Updated Successful");
      })
      .addCase(updateReminderTypesAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to Update Remainder types";
        errorToast(state.error);
      })
      .addCase(deleteReminderTypesAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteReminderTypesAsync.fulfilled, (state, action) => {
        state.remainderTypeData = state.remainderTypeData.filter(
          (classItem) => classItem.id !== action.payload.reminderTypeId
        );
        successToast("Remainder types Delete successfully");
      })
      .addCase(deleteReminderTypesAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to Delete Remainder types";
        errorToast(state.error);
      })
      .addCase(fetchDashboardDataAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDashboardDataAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to fetch Remainder types";
        errorToast(state.error);
      })
      .addCase(fetchDashboardDataAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.dashboardData = action.payload;
      })
      .addCase(fetchReminderSettingOfSchoolAsync.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchReminderSettingOfSchoolAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.remainderSettingsData = action.payload.data;
      })
      .addCase(fetchReminderSettingOfSchoolAsync.rejected, (state, action) => {
        state.fetchLoading = false;
        state.error = action.error.message || "Failed to fetch school groups";
        errorToast(state.error);
      })
      .addCase(createReminderSettingOfSchoolAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        createReminderSettingOfSchoolAsync.fulfilled,
        (state, action) => {
          state.loading = false;
          state.remainderSettingsData.push(action.payload.data);
          successToast(action.payload?.message);
        }
      )
      .addCase(createReminderSettingOfSchoolAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to create Remainder settings";
        errorToast(state.error);
      })

      .addCase(
        updateReminderSettingOfSchoolAsync.fulfilled,
        (state, action) => {
          state.loading = false;
          state.error = null;
          successToast("Remainder settings Updated Successful");
        }
      )
      .addCase(updateReminderSettingOfSchoolAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to Update Remainder settings";
        errorToast(state.error);
      })
      .addCase(updateReminderSettingOfSchoolAsync.pending, (state) => {
        state.loading = true;
      })

      .addCase(deleteReminderSettingOfSchoolAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        deleteReminderSettingOfSchoolAsync.fulfilled,
        (state, action) => {
          state.remainderSettingsData = state.remainderSettingsData.filter(
            (classItem) => classItem.id !== action.payload.reminderSettingId
          );
          successToast("Remainder types Delete successfully");
        }
      )
      .addCase(deleteReminderSettingOfSchoolAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to Delete Remainder types";
        errorToast(state.error);
      })
      .addCase(fetchReminderSettingOfSchoolByIdAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchReminderSettingOfSchoolByIdAsync.rejected,
        (state, action) => {
          state.loading = false;
          state.error =
            (action.payload as string) || "Failed to fetch Remainder types";
          errorToast(state.error);
        }
      )
      .addCase(
        fetchReminderSettingOfSchoolByIdAsync.fulfilled,
        (state, action) => {
          state.fetchLoading = false;
          state.remainderSettingsByIdData = action.payload;
        }
      )

      .addCase(fetchAllSchoolAdminAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllSchoolAdminAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to fetch School Group Admin";
        errorToast(state.error);
      })
      .addCase(fetchAllSchoolAdminAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.schoolgroupAdminData = action.payload.schoolGroupAdmin;
      })
      .addCase(createSchoolGroupAdminAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSchoolGroupAdminAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.schoolgroupAdminData.push(action.payload.data);
        successToast(action.payload?.message);
      })
      .addCase(createSchoolGroupAdminAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to create School Group Admin";
        errorToast(state.error);
      })
      .addCase(updateSchoolGroupAdminAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        successToast("School Group Admin Updated Successful");
      })
      .addCase(updateSchoolGroupAdminAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to Update School Group Admin";
        errorToast(state.error);
      })
      .addCase(updateSchoolGroupAdminAsync.pending, (state) => {
        state.loading = true;
      });
  },
});

export default systemAdminSlice.reducer;
