import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { TableData } from "../../../core/data/interface";
import { all_routes } from "../../router/all_routes";
import Table from "../../../core/common/dataTable/index";
import { useAppDispatch, useAppSelector } from "../../../core/data/redux/hooks";
import DeleteConfirmationModal from "../../../core/common/delete-confirmation-modal";

import { deleteAcademicYearAsync } from "../../../core/data/redux/features/academicYear/academicYearSlice";
import FeeCategorySchoolMoadl from "./components/fee-category-school-form-modal";
import {
  fetchSchoolGroupAsync,
  fetchSchoolByGroupIdAsync,
} from "../../../core/data/redux/features/systemAdmin/systemAdminSlice";
import { fetchAcademicYearsAsync } from "../../../core/data/redux/features/academicYear/academicYearSlice";
import {
  fetchFeeCategoryOfSchoolAsync,
  deleteFeeCategoryOfSchoolAsync,
} from "../../../core/data/redux/features/systemAdmin/systemAdminSlice";
import SchoolSelection from "../../../core/common/SchoolSelection";

interface SchoolData {
  [key: string]: number;
  // add other properties if needed
}
interface GroupData {
  [key: string]: string;
  // add other properties if needed
}

const FeeCategorySchool = () => {
  const routes = all_routes;
  const [selectedschool, setselectedschool] = useState<TableData | null>(null);
  const [schoolData, setSchoolData] = useState<SchoolData | null>(null);
  const [groupData, setGroupData] = useState<GroupData | null>(null);

  const dispatch = useAppDispatch();
  const { schoolGroupData, feeCategorySchoolData, schoolList, fetchLoading } =
    useAppSelector((state) => state.systemAdmin);

  useEffect(() => {
    if (schoolData?.id) {
      console.log(schoolData?.id);
      dispatch(
        fetchFeeCategoryOfSchoolAsync({ schoolId: Number(schoolData?.id) })
      );
    }
  }, [dispatch, schoolData]);
  const schoolOptions = schoolList.map((group: any) => ({
    value: group.id,
    label: group.schoolName,
  }));

  const schoolgroupsOptions = schoolGroupData.map((group: any) => ({
    value: group.id,
    label: group.schoolGroupName,
  }));

  const columns = [
    {
      title: "Fee Category Name",
      dataIndex: "feeCategoryName",
      sorter: (a: TableData, b: TableData) =>
        a.academicYearName.length - b.academicYearName.length,
    },
    {
      title: "Fee Category Display Name",
      dataIndex: "feeCategoryDisplayName",
      sorter: (a: TableData, b: TableData) =>
        a.academicYearDisplayName.length - b.academicYearDisplayName.length,
    },
    {
      title: "Fee Category Display Name",
      dataIndex: "feeCategoryDescription",
      sorter: (a: TableData, b: TableData) =>
        a.academicYearDisplayName.length - b.academicYearDisplayName.length,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: TableData) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <div className="dropdown">
                <Link
                  to="#"
                  className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="ti ti-dots-vertical fs-14" />
                </Link>
                <ul className="dropdown-menu dropdown-menu-right p-3">
                  <li>
                    <div
                      className="dropdown-item rounded-1"
                      onClick={() => {
                        setselectedschool(record);
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#add_feeCategory_to_school"
                    >
                      <i className="ti ti-edit-circle me-2" />
                      Edit
                    </div>
                  </li>
                  <li>
                    <div
                      className="dropdown-item rounded-1"
                      onClick={() => {
                        setselectedschool(record);
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#delete-modal"
                    >
                      <i className="ti ti-trash-x me-2" />
                      Delete
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </>
        );
      },
    },
  ];
  return (
    <>
      <div className="page-wrapper">
        <div className="content border">
          <SchoolSelection
            setSchoolData={setSchoolData}
            setGroupData={setGroupData}
          />
        </div>
      </div>

      {schoolData?.id && (
        <div>
          <div className="page-wrapper">
            <div className="content">
              {/* Page Header */}
              <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
                <div className="my-auto mb-2">
                  <h3 className="page-title mb-1">Fee Category</h3>
                  <nav>
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <Link to={routes.adminDashboard}>Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">Fee Category </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        All Fee Category
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
                  {/* <TooltipOption /> */}
                  <div className="mb-2">
                    <div
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#add_feeCategory_to_school"
                      onClick={() => {
                        setselectedschool(null);
                      }}
                    >
                      <i className="ti ti-square-rounded-plus-filled me-2" />
                      Add Fee Category
                    </div>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
              {/* Guardians List */}
              <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                  <h4 className="mb-3">Fee Category</h4>
                  <div className="d-flex align-items-center flex-wrap"></div>
                </div>
                <div className="card-body p-0 py-3">
                  {/* Guardians List */}
                  <Table
                    columns={columns}
                    dataSource={feeCategorySchoolData}
                    loading={fetchLoading}
                    // Selection={true}
                  />
                  {/* /Guardians List */}
                </div>
              </div>
            </div>
          </div>

          <div>
            {/* Add Edit Class Section */}
            <FeeCategorySchoolMoadl
              schoolId={schoolData?.id}
              selectedschool={selectedschool}
            />
            {/* /Add Edit Class Section */}

            {/* Delete Modal */}
            <DeleteConfirmationModal
              onConfirm={async () => {
                if (selectedschool) {
                  const result = await dispatch(
                    deleteFeeCategoryOfSchoolAsync({
                      schoolId: schoolData?.id,
                      feeCategoryId: selectedschool.id,
                    })
                  );
                  if (result) {
                    const cancelButton = document.getElementById(
                      "delete-cancel"
                    ) as HTMLElement;
                    cancelButton.click();
                    setselectedschool(null);
                    await dispatch(
                      fetchFeeCategoryOfSchoolAsync({
                        schoolId: schoolData?.id,
                      })
                    ).unwrap();
                  }
                }
              }}
            />
            {/* /Delete Modal */}
          </div>
        </div>
      )}
    </>
  );
};

export default FeeCategorySchool;
