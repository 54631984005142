import React, { useEffect, useRef, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { all_routes } from "../../router/all_routes";
import { TableData } from "../../../core/data/interface";
import Table from "../../../core/common/dataTable/index";
import { useAppDispatch, useAppSelector } from "../../../core/data/redux/hooks";

import { fetchSchooladminAsync } from "../../../core/data/redux/features/systemAdmin/systemAdminSlice";
import SchoolsAdminFormModal from "./components/school-admin-form-modal";

const SchoolAdmin = () => {
  const routes = all_routes;
  const dispatch = useAppDispatch();
  const [selectedschooladmin, setselectedschooladmin] =
    useState<TableData | null>(null);

  const { schoolAdminData, fetchLoading } = useAppSelector(
    (state) => state.systemAdmin
  );

  console.log("Redux State:", { schoolAdminData, fetchLoading });
  useEffect(() => {
    dispatch(fetchSchooladminAsync());
  }, [dispatch]);

  const columns = [
    // {
    //   title: "School Group Display Name",
    //   dataIndex: "schoolGroupDisplayName",
    //   sorter: (a: TableData, b: TableData) =>
    //     a.schoolGroupDisplayName.length - b.schoolGroupDisplayName.length,
    // },
    // {
    //   title: "School Group Address",
    //   dataIndex: "schoolGroupAddress",
    //   sorter: (a: TableData, b: TableData) =>
    //     a.schoolGroupAddress.length - b.schoolGroupAddress.length,
    // },
    {
      title: "First Name",
      dataIndex: "firstName",
      sorter: (a: TableData, b: TableData) =>
        a.contactPersonName.length - b.contactPersonName.length,
    },
    {
      title: "LastName",
      dataIndex: "lastName",
      sorter: (a: TableData, b: TableData) =>
        a.schoolGroupName.length - b.schoolGroupName.length,
    },
    {
      title: "Gender",
      dataIndex: "genderName",
      sorter: (a: TableData, b: TableData) =>
        a.contactPersonPhoneNo.length - b.contactPersonPhoneNo.length,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a: TableData, b: TableData) => a.email.length - b.email.length,
    },
    {
      title: "PhoneNumber",
      dataIndex: "phoneNumber",
      sorter: (a: TableData, b: TableData) => a.email.length - b.email.length,
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: TableData) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <div className="dropdown">
                <Link
                  to="#"
                  className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="ti ti-dots-vertical fs-14" />
                </Link>
                <ul className="dropdown-menu dropdown-menu-right p-3">
                  <li>
                    <div
                      className="dropdown-item rounded-1"
                      onClick={() => {
                        setselectedschooladmin(record);
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#add_schoolgroup"
                    >
                      <i className="ti ti-edit-circle me-2" />
                      Edit
                    </div>
                  </li>
                  {/* <li>
                    <div
                      className="dropdown-item rounded-1"
                      onClick={() => {
                        setselectedschooladmin(record);
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#delete-modal"
                    >
                      <i className="ti ti-trash-x me-2" />
                      Delete
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">School Admin</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <p>Dashboard</p>
                  </li>
                  <li className="breadcrumb-item">Schools</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    All Schools Admin
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              {/* <TooltipOption /> */}

              <div className="mb-2">
                <div
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#add_schoolgroup"
                  onClick={() => {
                    setselectedschooladmin(null);
                  }}
                >
                  <i className="ti ti-square-rounded-plus-filled me-2" />
                  Add School Admin
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Students List */}
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              {/* <h4 className="mb-3">School Group List</h4> */}
              <div className="d-flex align-items-center flex-wrap"></div>
            </div>
            <div className="card-body p-0 py-3">
              {/* Student List */}
              <Table
                dataSource={schoolAdminData}
                columns={columns}
                // loading={fetchLoading}
              />
              {/* /Student List */}
            </div>
          </div>
        </div>
      </div>
      {/* <SchoolModals /> */}
      <SchoolsAdminFormModal selectedschooladmin={selectedschooladmin} />
    </>
  );
};

export default SchoolAdmin;
