export const createSchoolsInitialValues = {
    schoolName: "",
    schoolDisplayName: "",
    schoolSubDomain:"",
    schoolLogo: "",
    schoolAddress:"",
    contactPersonName:"",
    contactPersonPhoneNo:"",
    contactPersonDesignation: "",
  };

