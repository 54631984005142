import { Navigate, Outlet, Route, Routes, useLocation } from "react-router";
import { authRoutes, publicRoutes } from "./router.link";
import Feature from "../feature";
import AuthFeature from "../authFeature";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../core/data/redux/hooks";
import { currentUserAsync } from "../../core/data/redux/features/auth/authSlice";
import { all_routes } from "./all_routes";
import { ToastContainer } from "react-toastify";

const routes = all_routes;

const ProtectedRoutes: React.FC = () => {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  return isAuthenticated ? (
    <Outlet />
  ) : isAuthenticated === null ? null : (
    <Navigate to={routes.login} replace />
  );
};

const ALLRoutes: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  //test

  useEffect(() => {
    dispatch(currentUserAsync());
  }, [location.pathname]);
  return (
    <>
      <ToastContainer />

      <Routes>
        <Route element={<ProtectedRoutes />}>
          <Route element={<Feature />}>
            {publicRoutes.map((route, idx) => (
              <Route path={route.path} element={route.element} key={idx} />
            ))}
          </Route>
        </Route>

        <Route element={<AuthFeature />}>
          {authRoutes.map((route, idx) => (
            <Route path={route.path} element={route.element} key={idx} />
          ))}
        </Route>

        <Route path="*" element={<Navigate to={routes.login} replace />} />
      </Routes>
    </>
  );
};

export default ALLRoutes;
