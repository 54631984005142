import dayjs from "dayjs";

export interface CreateAcademicYearInterface {
  academicYearName: string;
  academicYearDisplayName: string;
  startDate: null | dayjs.Dayjs;
  endDate: null | dayjs.Dayjs;
}

export const createAcademicYearInitialValues: CreateAcademicYearInterface = {
  academicYearName: "",
  academicYearDisplayName: "",
  startDate: null,
  endDate: null,
};
