import apiClient from "../../../api/apiClient";
import { CreateMultipleAdmissionRequest } from "./admissionTypes";

const BASE_PATH = "/admission";

export const generateExcelForMultipleAdmission = async (
  schoolId: number,
  academicYearId: number,
  classId: number,
  schoolSubDomain: string,
  groupSubDomain: string
): Promise<{ data: any; message: string }> => {
  const response = await apiClient.post(
    `${BASE_PATH}/generate-excel-for-creating-multiple-admission/${schoolId}/${academicYearId}/${classId}`,
    {},
    {
      headers: {
        Subdomain: schoolSubDomain || "",
        GroupSubDomain: groupSubDomain || "",
      },
    }
  );
  return response?.data;
};

export const createMultipleAdmissions = async (
  schoolId: number,
  admissionsData: CreateMultipleAdmissionRequest,
  schoolSubDomain: string,
  groupSubDomain: string
): Promise<{ data: any; message: string }> => {
  const response = await apiClient.post(
    `${BASE_PATH}/create-multiple-admission/${schoolId}`,
    admissionsData,
    {
      headers: {
        Subdomain: schoolSubDomain || "",
        GroupSubDomain: groupSubDomain || "",
      },
    }
  );
  return response?.data;
};

export const getRegisterMultipleStudentsBySchoolAndClass = async (
  schoolId: number,
  academicYearName: string,
  className: string,
  schoolSubDomain: string,
  groupSubDomain: string
): Promise<{ data: any; message: string }> => {
  const params = new URLSearchParams({
    className: className,
    academicYearName: academicYearName,
  });
  const response = await apiClient.get(
    `${BASE_PATH}/get-register-multiple-students-by-school-and-class/${schoolId}?${params}`,
    {
      headers: {
        Subdomain: schoolSubDomain || "",
        GroupSubDomain: groupSubDomain || "",
      },
    }
  );
  return response?.data;
};
