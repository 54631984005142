import { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../core/data/redux/hooks";
import { loginAsync } from "../../../core/data/redux/features/auth/authSlice";
import { ErrorMessage, Formik } from "formik";
import { all_routes } from "../../router/all_routes";
import { loginInitialValues } from "./initialValue";
import { loginValidationSchema } from "./schema";

const Login = () => {
  const routes = all_routes;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  // const schoolData = useAppSelector((state) => state.auth.schoolData);

  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  const date = () => {
    return new Date().getFullYear();
  };

  if (isAuthenticated === null) {
    return null;
  }

  if (isAuthenticated) {
    return <Navigate to={routes.adminDashboard} />;
  }

  return (
    <div className="container-fuild">
      <div className="w-100 overflow-hidden position-relative flex-wrap d-block vh-100">
        <div className="row">
          <div className="col-lg-6">
            <div className="login-background d-lg-flex align-items-center justify-content-center d-lg-block d-none flex-wrap vh-100 overflowy-auto">
              {/* <div>
                <ImageWithBasePath
                  src="https://eschool-bucket-dev.s3.ap-south-1.amazonaws.com/schools/schoolLogo/15420/b4b789be-180c-40ff-aa1b-f55473ce64e7_e-admin.png"
                  alt=""
                />
              </div> */}
              {/* <div className="authen-overlay-item  w-100 p-4"> */}
              <div className="authen-overlay-item p-4">
                {/* <h4 className="text-white mb-3">What's New on eSchool-App !!!</h4>
                <div className="d-flex align-items-center flex-row mb-3 justify-content-between p-3 br-5 gap-3 card">
                  <div>
                    <h6>Summer Vacation Holiday Homework</h6>
                    <p className="mb-0 text-truncate">
                      The school will remain closed from April 20th to June...
                    </p>
                  </div>
                  <Link to="#">
                    <i className="ti ti-chevrons-right" />
                  </Link>
                </div>
                <div className="d-flex align-items-center flex-row mb-3 justify-content-between p-3 br-5 gap-3 card">
                  <div>
                    <h6>New Academic Session Admission Start(2024-25)</h6>
                    <p className="mb-0 text-truncate">
                      An academic term is a portion of an academic year, the
                      time ....
                    </p>
                  </div>
                  <Link to="#">
                    <i className="ti ti-chevrons-right" />
                  </Link>
                </div>
                <div className="d-flex align-items-center flex-row mb-3 justify-content-between p-3 br-5 gap-3 card">
                  <div>
                    <h6>Date sheet Final Exam Nursery to Sr.Kg</h6>
                    <p className="mb-0 text-truncate">
                      Dear Parents, As the final examination for the session
                      2024-25 is ...
                    </p>
                  </div>
                  <Link to="#">
                    <i className="ti ti-chevrons-right" />
                  </Link>
                </div>
                <div className="d-flex align-items-center flex-row mb-3 justify-content-between p-3 br-5 gap-3 card">
                  <div>
                    <h6>Annual Day Function</h6>
                    <p className="mb-0 text-truncate">
                      Annual functions provide a platform for students to
                      showcase their...
                    </p>
                  </div>
                  <Link to="#">
                    <i className="ti ti-chevrons-right" />
                  </Link>
                </div>
                <div className="d-flex align-items-center flex-row mb-0 justify-content-between p-3 br-5 gap-3 card">
                  <div>
                    <h6>Summer Vacation Holiday Homework</h6>
                    <p className="mb-0 text-truncate">
                      The school will remain closed from April 20th to June 15th
                      for summer...
                    </p>
                  </div>
                  <Link to="#">
                    <i className="ti ti-chevrons-right" />
                  </Link>
                </div> */}
                <img
                  alt="Profile"
                  style={{ height: "25vh", objectFit: "contain" }}
                  src="https://eschoolapp.com/wp-content/uploads/2025/02/eSchoolApp-Logo-1.webp"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="row justify-content-center align-items-center vh-100 overflow-auto flex-wrap ">
              <div className="col-md-8 mx-auto p-4">
                <Formik
                  initialValues={loginInitialValues}
                  validationSchema={loginValidationSchema}
                  onSubmit={async (values, actions) => {
                    try {
                      const result = await dispatch(
                        loginAsync(values)
                      ).unwrap();
                      if (result) {
                        navigate("/index");
                      }
                    } catch (error) {
                      console.error(error);
                    }
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div>
                        <div className=" mx-auto text-center">
                          <img
                            alt="Profile"
                            className="mb-2"
                            style={{ height: "10vh", objectFit: "contain" }}
                            src="https://eschoolapp.com/wp-content/uploads/2025/02/eSchoolApp-Logo-1.webp"
                          />
                          {/* <ImageWithBasePath
                            src="assets/img/authentication/authentication-logo.svg"
                            className="img-fluid"
                            alt="Logo"
                          /> */}
                        </div>
                        <div className="card">
                          <div className="card-body pb-3">
                            <div className=" mb-4">
                              <h2 className="mb-2">Welcome</h2>
                              <p className="mb-0">
                                Please enter your details to sign in
                              </p>
                            </div>
                            {/* <div className="mt-4">
                          <div className="d-flex align-items-center justify-content-center flex-wrap">
                            <div className="text-center me-2 flex-fill">
                              <Link
                                to="#"
                                className="bg-primary br-10 p-2 btn btn-primary  d-flex align-items-center justify-content-center"
                              >
                                <ImageWithBasePath
                                  className="img-fluid m-1"
                                  src="assets/img/icons/facebook-logo.svg"
                                  alt="Facebook"
                                />
                              </Link>
                            </div>
                            <div className="text-center me-2 flex-fill">
                              <Link
                                to="#"
                                className=" br-10 p-2 btn btn-outline-light  d-flex align-items-center justify-content-center"
                              >
                                <ImageWithBasePath
                                  className="img-fluid  m-1"
                                  src="assets/img/icons/google-logo.svg"
                                  alt="Facebook"
                                />
                              </Link>
                            </div>
                            <div className="text-center flex-fill">
                              <Link
                                to="#"
                                className="bg-dark br-10 p-2 btn btn-dark d-flex align-items-center justify-content-center"
                              >
                                <ImageWithBasePath
                                  className="img-fluid  m-1"
                                  src="assets/img/icons/apple-logo.svg"
                                  alt="Apple"
                                />
                              </Link>
                            </div>
                          </div>
                        </div> */}
                            {/* <div className="login-or">
                          <span className="span-or">Or</span>
                        </div> */}
                            <div className="mb-3 ">
                              <label className="form-label">User Name</label>
                              <div className="input-icon mb-3 position-relative">
                                <span className="input-icon-addon">
                                  <i className="ti ti-mail" />
                                </span>
                                <input
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.userName}
                                  name="userName"
                                  className="form-control"
                                />
                                <ErrorMessage
                                  name="userName"
                                  className="text-danger"
                                  component="div"
                                />
                              </div>
                              <label className="form-label">Password</label>
                              <div className="pass-group">
                                <input
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.password}
                                  type={isPasswordVisible ? "text" : "password"}
                                  name="password"
                                  className="pass-input form-control"
                                />
                                <span
                                  className={`ti toggle-password ${
                                    isPasswordVisible ? "ti-eye" : "ti-eye-off"
                                  }`}
                                  onClick={togglePasswordVisibility}
                                />
                                <ErrorMessage
                                  name="password"
                                  className="text-danger"
                                  component="div"
                                />
                              </div>
                            </div>
                            {/* <div className="form-wrap form-wrap-checkbox">
                              <div className="d-flex align-items-center">
                                <div className="form-check form-check-md mb-0">
                                  <input
                                    className="form-check-input mt-0"
                                    type="checkbox"
                                  />
                                </div>
                                <p className="ms-1 mb-0 ">Remember Me</p>
                              </div>
                              <div className="text-end ">
                            <Link
                              to={routes.forgotPassword}
                              className="link-danger"
                            >
                              Forgot Password?
                            </Link>
                          </div>
                            </div> */}
                          </div>
                          <div className="p-4 pt-0">
                            <div className="mb-3">
                              <button
                                type="submit"
                                className="btn btn-primary w-100"
                                disabled={isSubmitting}
                              >
                                Sign In
                              </button>
                            </div>
                            {/* <div className="text-center">
                          <h6 className="fw-normal text-dark mb-0">
                            Don’t have an account?{" "}
                            <Link to={routes.register} className="hover-a ">
                              {" "}
                              Create Account
                            </Link>
                          </h6>
                        </div> */}
                          </div>
                        </div>
                        <div className="mt-5 text-center">
                          <p className="mb-0 ">
                            Copyright © {date()} - eSchool-App
                          </p>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
