export const createReminderSettingsInitialValues = {
  reminderTypeId: null,
  toCCBCCSettings: {
    toSetting: {
      student: false,
      parent: false,
      teacher: false,
      schoolAdmin: false,
      other: [],
    },
    ccSetting: {
      student: false,
      parent: false,
      teacher: false,
      schoolAdmin: false,
      other: [],
    },
    bccSetting: {
      student: false,
      parent: false,
      teacher: false,
      schoolAdmin: false,
      other: [],
    },
  },
  frequency: [],
  freqfake: [],
  isActive: false,
};
