import apiClient from "../../../api/apiClient";
import {
  FetchSchoolGroupResponse,
  FetchSchoolsResponse,
  SchoolGroup,
  Schools,
  SchoolAdmin,
  FeeCategorySchool,
  DashboardData,
  RemainderTypes,
  RemainderSettingsTypes,
  RemainderSettingsTypesByTd,
  SchoolGroupAdmin,
} from "./systemAdminType";

const BASE_PATH = "/system-admin";

export const uploadMultipleFiles = async (
  formData: any,
  fileCategory: string
) => {
  const response = await apiClient.post(
    `${BASE_PATH}/upload-multiple-file?fileCategory=${fileCategory}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response?.data;
};

// API call to fetch school groups

export const fetchSchoolGroup = async (): Promise<{
  schoolGroup: SchoolGroup[];
  message: string;
}> => {
  const response = await apiClient.get(`${BASE_PATH}/school-group`);
  return response?.data;
};

// API call to fetch schools by group ID
export const fetchSchoolByGroupId = async (
  id: number
): Promise<FetchSchoolsResponse> => {
  const response = await apiClient.get<FetchSchoolsResponse>(
    `${BASE_PATH}/schools/${id}`
  );
  return response.data;
};

export const createSchoolGroup = async (
  newSchoolGroup: SchoolGroup
): Promise<{ data: SchoolGroup; message: string }> => {
  const response = await apiClient.post(
    `${BASE_PATH}/school-group`,
    newSchoolGroup
  );
  return response?.data;
};

// API call to update a school group
export const updateSchoolGroup = async (
  groupId: number,
  updatedSchoolGroup: SchoolGroup
): Promise<{ data: SchoolGroup; message: string }> => {
  const response = await apiClient.put(
    `${BASE_PATH}/school-group/${groupId}`,
    updatedSchoolGroup
  );
  return response?.data;
};

// API call to create a school in group

export const createSchoolInGroup = async (
  groupId: number,
  newSchoolInGroup: Schools
): Promise<{ data: Schools; message: string }> => {
  const response = await apiClient.post(
    `${BASE_PATH}/school/${groupId}`,
    newSchoolInGroup
  );
  return response?.data;
};

// API call to create a school in group

export const updateSchoolInGroup = async (
  groupId: number,
  id: number,
  updatedSchoolGroup: Schools
): Promise<{ data: Schools; message: string }> => {
  const response = await apiClient.put(
    `${BASE_PATH}/school/${groupId}/${id}`,
    updatedSchoolGroup
  );
  return response?.data;
};

// API call to create a school Admin

export const createschoolAdmin = async (
  schoolId: number,
  newSchoolAdmin: SchoolAdmin
): Promise<{ data: SchoolAdmin; message: string }> => {
  const response = await apiClient.post(
    `${BASE_PATH}/create-school-admin/${schoolId}`,
    newSchoolAdmin
  );
  return response?.data;
};

export const fetchSchooladmin = async (): Promise<{
  schoolAdmin: SchoolAdmin[];
  message: string;
}> => {
  const response = await apiClient.get(`${BASE_PATH}/get-all-school-admin`);
  return response?.data;
};

// API call to update school Admin
export const updateSchooladmin = async (
  schoolId: number,
  schoolAdminId: number,
  updateSchoolAdmin: SchoolAdmin
): Promise<SchoolAdmin> => {
  const response = await apiClient.put<SchoolAdmin>(
    `${BASE_PATH}/update-school-admin/${schoolId}/${schoolAdminId}`,
    updateSchoolAdmin
  );
  return response.data;
};
export const fetchFeeCategoryOfSchool = async (
  schoolId: number
): Promise<{ data: FeeCategorySchool[]; message: string }> => {
  const response = await apiClient.get(
    `${BASE_PATH}/get-fee-category-of-school/${schoolId}`
  );
  return response?.data;
};

// API call to create fee category of school
export const createFeeCategoryOfSchool = async (
  schoolId: number,
  createfeecategory: FeeCategorySchool
): Promise<FeeCategorySchool> => {
  const response = await apiClient.post<FeeCategorySchool>(
    `${BASE_PATH}/add-fee-category-to-school/${schoolId}`,
    createfeecategory
  );
  return response.data;
};

// API call to update fee category of school
export const updateFeeCategoryOfSchool = async (
  schoolId: number,
  feecategoryId: number,
  updatefeecategory: FeeCategorySchool
): Promise<FeeCategorySchool> => {
  const response = await apiClient.put<FeeCategorySchool>(
    `${BASE_PATH}/update-fee-category/${schoolId}/${feecategoryId}`,
    updatefeecategory
  );
  return response.data;
};

export const deleteFeeCategoryOfSchool = async (
  schoolId: number,
  feecategoryId: number
): Promise<void> => {
  await apiClient.delete(
    `${BASE_PATH}/delete-fee-category-from-school/${schoolId}/${feecategoryId}`
  );
};

export const fetchReminderTypes = async (): Promise<{
  data: RemainderTypes[];
  message: string;
}> => {
  const response = await apiClient.get(`${BASE_PATH}/reminder-types`);
  return response?.data;
};

export const createReminderTypes = async (
  createremaindertype: RemainderTypes
): Promise<RemainderTypes> => {
  const response = await apiClient.post<RemainderTypes>(
    `${BASE_PATH}/reminder-types`,
    createremaindertype
  );
  return response.data;
};

export const updateReminderTypes = async (
  reminderTypeId: number,
  updateremaindertype: RemainderTypes
): Promise<RemainderTypes> => {
  const response = await apiClient.put<RemainderTypes>(
    `${BASE_PATH}/reminder-types/${reminderTypeId}`,
    updateremaindertype
  );
  return response.data;
};

export const deleteReminderTypes = async (
  reminderTypeId: number
): Promise<void> => {
  await apiClient.delete(`${BASE_PATH}/reminder-types/${reminderTypeId}`);
};

export const fetchSystemAdminDashboardData = async (): Promise<{
  data: DashboardData;
  message: string;
}> => {
  const response = await apiClient.get(`${BASE_PATH}/system-dashboard`);
  return response?.data;
};

export const fetchReminderSettingOfSchool = async (
  schoolId: number
): Promise<{ data: RemainderSettingsTypes[]; message: string }> => {
  const response = await apiClient.get<{
    data: RemainderSettingsTypes[];
    message: string;
  }>(`${BASE_PATH}/reminder-settings/${schoolId}`);
  return response.data;
};

export const createReminderSettingOfSchool = async (
  schoolId: number,
  createremaindersettings: RemainderSettingsTypes
): Promise<RemainderSettingsTypes> => {
  const response = await apiClient.post<RemainderSettingsTypes>(
    `${BASE_PATH}/reminder-settings/${schoolId}`,
    createremaindersettings
  );
  return response.data;
};

export const updateReminderSettingOfSchool = async (
  schoolId: number,
  id: number,
  updateremaindersettings: RemainderSettingsTypes
): Promise<RemainderSettingsTypes> => {
  const response = await apiClient.put<RemainderSettingsTypes>(
    `${BASE_PATH}/reminder-settings/${schoolId}/${id}`,
    updateremaindersettings
  );
  return response.data;
};

export const deleteReminderSettingOfSchool = async (
  schoolId: number,
  reminderSettingId: number
): Promise<void> => {
  await apiClient.delete(
    `${BASE_PATH}/reminder-settings/${schoolId}/${reminderSettingId}`
  );
};

export const fetchReminderSettingOfSchoolById = async (
  schoolId: number,
  reminderSettingId: number
): Promise<{ data: RemainderSettingsTypesByTd; message: string }> => {
  const response = await apiClient.get<{
    data: RemainderSettingsTypesByTd;
    message: string;
  }>(`${BASE_PATH}/reminder-settings/${schoolId}/${reminderSettingId}`);
  return response?.data;
};

export const fetchAllSchoolAdmin = async (): Promise<{
  schoolGroupAdmin: SchoolGroupAdmin[];
  message: string;
}> => {
  const response = await apiClient.get<{
    schoolGroupAdmin: SchoolGroupAdmin[];
    message: string;
  }>(`${BASE_PATH}/get-all-school-group-admin`);
  return response.data;
};

export const createSchoolGroupAdmin = async (
  schoolGroupId: number,
  newSchoolGroupAdmin: SchoolGroupAdmin
): Promise<{ data: SchoolGroupAdmin; message: string }> => {
  const response = await apiClient.post(
    `${BASE_PATH}/create-school-group-admin/${schoolGroupId}`,
    newSchoolGroupAdmin
  );
  return response?.data;
};

export const updateSchoolGroupAdmin = async (
  schoolGroupId: number,
  schoolGroupAdminId: number,
  updatedSchoolGroupAdmin: SchoolGroupAdmin
): Promise<SchoolGroupAdmin> => {
  const response = await apiClient.put<SchoolGroupAdmin>(
    `${BASE_PATH}/update-school-group-admin/${schoolGroupId}/${schoolGroupAdminId}`,
    updatedSchoolGroupAdmin
  );
  return response.data;
};
