import { all_routes } from "../../../feature-module/router/all_routes";
const routes = all_routes;

export const SidebarData = [
  {
    label: "MAIN",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Main",
    submenuItems: [
      {
        label: "Dashboard",
        icon: "ti ti-layout-dashboard",
        submenu: true,
        showSubRoute: false,

        submenuItems: [
          { label: "System Admin Dashboard", link: routes.adminDashboard },
        ],
      },
    ],
  },
  {
    label: "School",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "School",
    submenuItems: [
      {
        label: "Schools",
        icon: "ti ti-ruler",
        submenu: true,
        showSubRoute: false,
        submenuItems: [
          { label: "Schools Groups", link: routes.schoolGroupList },
          { label: "School Group Admin", link: routes.schoolGroupAdmin },
          { label: "School", link: routes.schoolList },
          { label: "School Admin", link: routes.schoolAdmin },
        ],
      },
    ],
  },
  {
    label: "Reminder",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Fee",

    submenuItems: [
      {
        label: "Reminder",
        icon: "ti ti-bell",
        submenu: true,
        showSubRoute: false,
        submenuItems: [
          { label: "Reminder Types", link: routes.reminderTypes },
          { label: "Reminder Types Settings", link: routes.reminderSettings },
        ],
      },
    ],
  },
  {
    label: "Academic",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Academic",

    submenuItems: [
      {
        label: "Academic Years",
        icon: "ti ti-calendar",
        submenu: true,
        showSubRoute: false,
        submenuItems: [
          { label: "All Academic Years", link: routes.academicYears },
        ],
      },
      {
        label: "Admission",
        icon: "ti ti-school",
        submenu: true,
        showSubRoute: false,
        submenuItems: [
          { label: "Multiple Admission", link: routes.multipleadmission },
        ],
      },
      {
        label: "Class",
        icon: "ti ti-book",
        submenu: true,
        showSubRoute: false,
        submenuItems: [
          { label: "Class", link: routes.classes },
          { label: "Sections", link: routes.classSection },
        ],
      },
    ],
  },
  {
    label: "Fee",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Fee",

    submenuItems: [
      {
        label: "Fee",
        icon: "ti ti-wallet",
        submenu: true,
        showSubRoute: false,
        submenuItems: [
          { label: "All Fee Category", link: routes.feeCategory },
          { label: "Fee Catelog", link: routes.feesCatalog },
        ],
      },
    ],
  },
];
