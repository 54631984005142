import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  AdmissionState,
  CreateMultipleAdmissionRequest,
} from "./admissionTypes";
import {
  createMultipleAdmissions,
  getRegisterMultipleStudentsBySchoolAndClass,
} from "./admissionApi";
import { errorToast, successToast } from "../../../../common/toast/toast";

const initialState: AdmissionState = {
  multipleAdmissionsData: [],
  loading: false,
  fetchLoading: false,
  error: null,
};

export const createMultipleAdmissionsAsync = createAsyncThunk(
  "admission/createMultipleAdmissions",
  async (
    {
      schoolId,
      admissionsData,
      schoolSubDomain,
      groupSubDomain,
    }: {
      schoolId: number;
      admissionsData: CreateMultipleAdmissionRequest;
      schoolSubDomain: string;
      groupSubDomain: string;
    },
    thunkAPI
  ) => {
    try {
      return await createMultipleAdmissions(
        schoolId,
        admissionsData,
        schoolSubDomain,
        groupSubDomain
      );
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to create multiple admissions"
      );
    }
  }
);

export const getRegisterMultipleStudentsBySchoolAndClassAsync =
  createAsyncThunk(
    "admission/getRegisterMultipleStudentsBySchoolAndClass",
    async (
      {
        schoolId,
        academicYearName,
        className,
        schoolSubDomain,
        groupSubDomain,
      }: {
        schoolId: number;
        academicYearName: string;
        className: string;
        schoolSubDomain: string;
        groupSubDomain: string;
      },
      thunkAPI
    ) => {
      try {
        return await getRegisterMultipleStudentsBySchoolAndClass(
          schoolId,
          academicYearName,
          className,
          schoolSubDomain,
          groupSubDomain
        );
      } catch (error: any) {
        return thunkAPI.rejectWithValue(
          error.response?.data?.message || "Failed to fetch registered students"
        );
      }
    }
  );

const admissionSlice = createSlice({
  name: "admission",
  initialState,
  reducers: {
    resetMultipleAdmissionsData: (state) => {
      state.multipleAdmissionsData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Create Multiple Admissions
      .addCase(createMultipleAdmissionsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createMultipleAdmissionsAsync.fulfilled, (state, action) => {
        state.loading = false;
        successToast(action.payload.message);
      })
      .addCase(createMultipleAdmissionsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to create multiple admissions";
        errorToast(state.error);
      })
      // Get Register Multiple Students
      .addCase(
        getRegisterMultipleStudentsBySchoolAndClassAsync.pending,
        (state) => {
          state.fetchLoading = true;
        }
      )
      .addCase(
        getRegisterMultipleStudentsBySchoolAndClassAsync.fulfilled,
        (state, action) => {
          state.fetchLoading = false;
          state.multipleAdmissionsData = action.payload.data;
        }
      )
      .addCase(
        getRegisterMultipleStudentsBySchoolAndClassAsync.rejected,
        (state, action) => {
          state.fetchLoading = false;
          state.error =
            (action.payload as string) || "Failed to fetch registered students";
          errorToast(state.error);
        }
      );
  },
});

export const { resetMultipleAdmissionsData } = admissionSlice.actions;
export default admissionSlice.reducer;
