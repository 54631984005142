import apiClient from "../../../api/apiClient";
import { AcademicYear } from "./academicYearType";

//Base URL

const BASE_PATH = "/system-admin";

export const fetchAcademicYearsOfSchool = async (
  schoolId: number
): Promise<{ data: AcademicYear[]; message: string }> => {
  const response = await apiClient.get(
    `${BASE_PATH}/get-academic-year-of-school/${schoolId}`
  );
  return response?.data;
};

export const createAcademicYear = async (
  schoolId: number,
  newAcademicYear: AcademicYear
): Promise<{ data: AcademicYear; message: string }> => {
  const response = await apiClient.post(
    `${BASE_PATH}/add-academic-year-to-school/${schoolId}`,
    newAcademicYear
  );
  return response?.data;
};

export const updateAcademicYear = async (
  schoolId: number,
  academicYearId: number,
  updatedAcademicYear: AcademicYear
): Promise<{ data: AcademicYear; message: string }> => {
  const response = await apiClient.put(
    `${BASE_PATH}/update-academic-year/${schoolId}/${academicYearId}`,
    updatedAcademicYear
  );
  return response?.data;
};

export const deleteAcademicYear = async (
  schoolId: number,
  academicYearId: number
): Promise<void> => {
  await apiClient.delete(
    `${BASE_PATH}/delete-academic-year-from-school/${schoolId}/${academicYearId}`
  );
};
