import { useState } from "react";
import { Formik, ErrorMessage, Field } from "formik";
import { useEffect, useMemo, useRef } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../core/data/redux/hooks";
import { createRemainderInitialValues } from "../initialValues";
import { createRemainderTypesValidationSchema } from "../schema";
import { errorToast } from "../../../../core/common/toast/toast";

import {
  createReminderTypesAsync,
  updateReminderTypesAsync,
  fetchReminderTypesAsync,
} from "../../../../core/data/redux/features/systemAdmin/systemAdminSlice";

const RemainderFormModal = ({ selectedremindertypes, groupId }: any) => {
  console.log(selectedremindertypes, "selectedschoolgroup");
  const dispatch = useAppDispatch();
  const formRef = useRef(null);
  useEffect(() => {
    const modalElement = document.getElementById("add_school");

    const handleModalClose = () => {
      if (formRef.current) {
        // @ts-ignore
        formRef.current.resetForm();
      }
    };

    modalElement?.addEventListener("hidden.bs.modal", handleModalClose);

    return () => {
      modalElement?.removeEventListener("hidden.bs.modal", handleModalClose);
    };
  }, []);

  const finalInitialValues = useMemo(() => {
    if (selectedremindertypes) {
      return {
        type: selectedremindertypes.type,
        description: selectedremindertypes.description,
      };
    }
    return createRemainderInitialValues;
  }, [selectedremindertypes]);

  //   const handelfileupload = async (
  //     file: any,
  //     name: string,
  //     setFieldValue: any
  //   ) => {
  //     if (file) {
  //       const formData = new FormData();
  //       formData.append("files", file);

  //       try {
  //         const response = await dispatch(
  //           uploadMultipleFilesAsync({
  //             formData,
  //             fileCategory: "schoolLogo",
  //           })
  //         ).unwrap();

  //         setFieldValue(name, response.uploadedFiles[0].fileUrl);
  //       } catch (error) {
  //         errorToast("Upload failed:");
  //       }
  //     }
  //   };

  return (
    <div className="modal fade" id="add_remainder_types">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              {selectedremindertypes
                ? "Edit Remainder Types"
                : "Add Remainder Types"}
            </h4>
            <button
              type="button"
              className="btn-close custom-btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="ti ti-x" />
            </button>
          </div>
          <Formik
            initialValues={finalInitialValues}
            enableReinitialize
            innerRef={formRef}
            validationSchema={createRemainderTypesValidationSchema}
            onSubmit={async (values, actions) => {
              console.log("Form Values Before Submitting:", values);
              try {
                let result;
                if (selectedremindertypes) {
                  result = await dispatch(
                    updateReminderTypesAsync({
                      reminderTypeId: selectedremindertypes.id,
                      updateremaindertype: values,
                    })
                  ).unwrap();
                } else {
                  result = await dispatch(
                    createReminderTypesAsync({
                      createremaindertype: values,
                    })
                  ).unwrap();
                }
                if (result) {
                  document.getElementById("btn-cancel")?.click();
                  await dispatch(fetchReminderTypesAsync()).unwrap();
                }
              } catch (error) {
                console.error("Error creating remainder types:", error);
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              setFieldTouched,
              values,
              resetForm,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Types</label>
                        <Field
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.type}
                          name="type"
                        />
                        <ErrorMessage
                          name="type"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Description</label>
                        <Field
                          as="textarea"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.description}
                          name="description"
                          style={{ height: "60px" }}
                        />
                        <ErrorMessage
                          name="description"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => resetForm()}
                    id="btn-cancel"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isSubmitting}
                  >
                    {selectedremindertypes
                      ? "Edit Remainder Types"
                      : "Add Remainder Types"}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default RemainderFormModal;
