import React, { useEffect, useRef, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { all_routes } from "../../router/all_routes";
import { TableData } from "../../../core/data/interface";
// import SchoolsFormModal from "./components/schools-form-modal";
import Table from "../../../core/common/dataTable/index";
import { useAppDispatch, useAppSelector } from "../../../core/data/redux/hooks";
import {
  fetchSchoolGroupAsync,
  fetchSchoolByGroupIdAsync,
} from "../../../core/data/redux/features/systemAdmin/systemAdminSlice";
import SchoolsFormModal from "./components/school-form-modal";
import Select from "react-select";

const SchoolList = () => {
  const routes = all_routes;
  const dispatch = useAppDispatch();
  const [selectedschool, setselectedschool] = useState<TableData | null>(null);

  const [groupId, setGroupId] = useState(null);

  const { schoolGroupData, schoolList, fetchLoading, error } = useAppSelector(
    (state) => state.systemAdmin
  );

  //   const { schoolData} = useAppSelector(
  //     (state) => state.systemAdmin
  //   );

  console.log("Redux State:", { schoolGroupData, fetchLoading, error });
  useEffect(() => {
    dispatch(fetchSchoolGroupAsync());

    if (groupId) {
      dispatch(fetchSchoolByGroupIdAsync(groupId));
    }
  }, [dispatch, groupId, selectedschool]);

  const handlechange = (e: any) => {
    // console.log(e.target.value);
    setGroupId(e.target.value);
  };
  const handlegroupchange = (selectedOption: any) => {
    setGroupId(selectedOption.value);
    // console.log(e.target.value, "change");
  };

  const schoolgroupsOptions = schoolGroupData.map((group: any) => ({
    value: group.id,
    label: group.schoolGroupName,
  }));

  const columns = [
    {
      title: "School Logo",
      dataIndex: "schoolLogo",
      render: (url: string) => (
        <img
          src={url}
          alt="School Logo"
          className="img-fluid"
          style={{ width: "50px", height: "50px", objectFit: "cover" }}
        />
      ),
    },
    {
      title: "School Name",
      dataIndex: "schoolName",
      sorter: (a: TableData, b: TableData) =>
        a.schoolName.length - b.schoolName.length,
    },
    {
      title: "Contact Person Name",
      dataIndex: "contactPersonName",
      sorter: (a: TableData, b: TableData) =>
        a.contactPersonName.length - b.contactPersonName.length,
    },
    {
      title: "Contact Person PhoneNo",
      dataIndex: "contactPersonPhoneNo",
      sorter: (a: TableData, b: TableData) =>
        a.contactPersonPhoneNo.length - b.contactPersonPhoneNo.length,
    },

    {
      title: "Contact Person Designation",
      dataIndex: "contactPersonDesignation",
      sorter: (a: TableData, b: TableData) =>
        a.contactPersonDesignation.length - b.contactPersonDesignation.length,
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: TableData) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <div className="dropdown">
                <Link
                  to="#"
                  className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="ti ti-dots-vertical fs-14" />
                </Link>
                <ul className="dropdown-menu dropdown-menu-right p-3">
                  <li>
                    <div
                      className="dropdown-item rounded-1"
                      onClick={() => {
                        setselectedschool(record);
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#add_school"
                    >
                      <i className="ti ti-edit-circle me-2" />
                      Edit
                    </div>
                  </li>
                  {/* <li>
                    <div
                      className="dropdown-item rounded-1"
                      onClick={() => {
                        setselectedschool(record);
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#delete-modal"
                    >
                      <i className="ti ti-trash-x me-2" />
                      Delete
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content border">
          {/* Page Header */}

          <div className="row d-flex justify-content-center">
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xxl-4 mb-3">
              <label className="form-label">
                Please Select The School Group First
              </label>
              <Select
                options={schoolgroupsOptions}
                onChange={(selectedOption) => handlegroupchange(selectedOption)}
                placeholder="Select School Group"
                isSearchable={true}
              />
            </div>
          </div>

          {groupId && (
            <>
              <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
                <div className="my-auto mb-2">
                  <h3 className="page-title mb-1">Schools</h3>
                  <nav>
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <p>Dashboard</p>
                      </li>
                      <li className="breadcrumb-item">School</li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        All Schools
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
                  {/* <TooltipOption /> */}

                  <div className="mb-2">
                    <div
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#add_school"
                      onClick={() => {
                        setselectedschool(null);
                      }}
                    >
                      <i className="ti ti-square-rounded-plus-filled me-2" />
                      Add School
                    </div>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
              {/* Students List */}
              <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                  <h4 className="mb-3">School List</h4>
                  <div className="d-flex align-items-center flex-wrap"></div>
                </div>
                <div className="card-body p-0 py-3">
                  {/* Student List */}
                  <Table
                    dataSource={schoolList}
                    columns={columns}
                    loading={fetchLoading}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {/* <SchoolModals /> */}
      <SchoolsFormModal groupId={groupId} selectedschool={selectedschool} />
    </>
  );
};

export default SchoolList;
