import React, { useEffect, useRef, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { all_routes } from "../../router/all_routes";
import { TableData } from "../../../core/data/interface";
// import SchoolsFormModal from "./components/schools-form-modal";
import Table from "../../../core/common/dataTable/index";
import { useAppDispatch, useAppSelector } from "../../../core/data/redux/hooks";
import {
  fetchReminderTypesAsync,
  fetchSchoolByGroupIdAsync,
  deleteReminderTypesAsync,
  fetchReminderSettingOfSchoolAsync,
  fetchReminderSettingOfSchoolByIdAsync,
} from "../../../core/data/redux/features/systemAdmin/systemAdminSlice";
import RemainderFormModal from "./components/reminder-form-modal";
import DeleteConfirmationModal from "../../../core/common/delete-confirmation-modal";
import { school } from "../../../core/common/selectoption/selectoption";
// import SchoolsFormModal from "./components/school-form-modal";
// import Select from "react-select";

const RemainderTypes = () => {
  const routes = all_routes;
  const dispatch = useAppDispatch();
  const [selectedremindertypes, setselectedremindertypes] =
    useState<TableData | null>(null);

  const [groupId, setGroupId] = useState(null);

  const { remainderTypeData,remainderSettingsData,remainderSettingsByIdData, fetchLoading, error } = useAppSelector(
    (state) => state.systemAdmin
  );

  //   const { schoolData} = useAppSelector(
  //     (state) => state.systemAdmin
  //   );

  console.log("Redux State:", { remainderTypeData,remainderSettingsByIdData,remainderSettingsData, fetchLoading, error });
  useEffect(() => {
    dispatch(fetchReminderTypesAsync());
    dispatch(fetchReminderSettingOfSchoolAsync({ schoolId: 1 }));
    // dispatch(fetchReminderSettingOfSchoolByIdAsync({ schoolId: 1 ,reminderSettingId:1}));

    if (groupId) {
      dispatch(fetchSchoolByGroupIdAsync(groupId));
    }
  }, [dispatch, groupId, selectedremindertypes]);

  const handlechange = (e: any) => {
    // console.log(e.target.value);
    setGroupId(e.target.value);
  };
  const handlegroupchange = (selectedOption: any) => {
    setGroupId(selectedOption.value);
    // console.log(e.target.value, "change");
  };

  //   const schoolgroupsOptions = schoolGroupData.map((group: any) => ({
  //     value: group.id,
  //     label: group.schoolGroupName,
  //   }));

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      sorter: (a: TableData, b: TableData) =>
        a.schoolName.length - b.schoolName.length,
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: (a: TableData, b: TableData) =>
        a.schoolName.length - b.schoolName.length,
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: TableData) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <div className="dropdown">
                <Link
                  to="#"
                  className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="ti ti-dots-vertical fs-14" />
                </Link>
                <ul className="dropdown-menu dropdown-menu-right p-3">
                  <li>
                    <div
                      className="dropdown-item rounded-1"
                      onClick={() => {
                        setselectedremindertypes(record);
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#add_remainder_types"
                    >
                      <i className="ti ti-edit-circle me-2" />
                      Edit
                    </div>
                  </li>
                  <li>
                    <div
                      className="dropdown-item rounded-1"
                      onClick={() => {
                        setselectedremindertypes(record);
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#delete-modal"
                    >
                      <i className="ti ti-trash-x me-2" />
                      Delete
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content border">
          {/* Page Header */}
          <>
            <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
              <div className="my-auto mb-2">
                <h3 className="page-title mb-1">Schools</h3>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <p>Dashboard</p>
                    </li>
                    <li className="breadcrumb-item">School</li>
                    <li className="breadcrumb-item active" aria-current="page">
                      All Schools
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
                {/* <TooltipOption /> */}

                <div className="mb-2">
                  <div
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#add_remainder_types"
                    onClick={() => {
                      setselectedremindertypes(null);
                    }}
                  >
                    <i className="ti ti-square-rounded-plus-filled me-2" />
                    Add Remainder Type
                  </div>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            {/* Students List */}
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                <h4 className="mb-3">Remainder Type List</h4>
                <div className="d-flex align-items-center flex-wrap"></div>
              </div>
              <div className="card-body p-0 py-3">
                {/* Student List */}
                <Table
                  dataSource={remainderTypeData}
                  columns={columns}
                  loading={fetchLoading}
                />
              </div>
            </div>
          </>
        </div>
      </div>
      {/* <SchoolModals /> */}
      <RemainderFormModal
        groupId={groupId}
        selectedremindertypes={selectedremindertypes}
      />

      <DeleteConfirmationModal
        onConfirm={async () => {
          try {
            if (selectedremindertypes) {
              const result = await dispatch(
                deleteReminderTypesAsync({ reminderTypeId: Number(selectedremindertypes.id) })
              ).unwrap();

              if (result) {
                //   if (schoolId) {
                let resultData = await dispatch(fetchReminderTypesAsync());
                if (resultData) {
                  const cancelButton = document.getElementById(
                    "delete-cancel"
                  ) as HTMLElement;
                  cancelButton.click();
                  setselectedremindertypes(null);
                }
                //   }
              }
            }
          } catch (error) {
            console.error(error);
          }
        }}
      />
    </>
  );
};

export default RemainderTypes;
