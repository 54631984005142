import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { currentUser, login } from "./authApi";
import { errorToast, successToast } from "../../../../common/toast/toast";

interface AuthState {
  isAuthenticated: boolean | null;
  user: {
    dob: string;
    email: string;
    firstName: string;
    genderId: number;
    id: number;
    lastName: string;
    phoneNumber: string;
    schoolId: number;
    userName: string;
  } | null;
  token: string | null;
  loading: boolean;
  error: string | null;
}

const initialState: AuthState = {
  isAuthenticated: null,
  user: null,
  token: null,
  loading: false,
  error: null,
};

// Async thunk for login
export const loginAsync = createAsyncThunk(
  "auth/login",
  async (credentials: { userName: string; password: string }, thunkAPI) => {
    try {
      const response = await login(credentials);
      localStorage.setItem("authToken", response.token);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Login failed"
      );
    }
  }
);

export const currentUserAsync = createAsyncThunk(
  "auth/currentUser",
  async (_, thunkAPI) => {
    try {
      const response = await currentUser();
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch current user"
      );
    }
  }
);

// Async thunk for logout
export const logoutAsync = createAsyncThunk("auth/logout", async () => {
  // await logout();

  return new Promise((resolve: any) => {
    setTimeout(() => {
      localStorage.removeItem("authToken");
      resolve();
    }, 1000);
  });
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // Optional for manual login/logout
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginAsync.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.user = action.payload.user;
        state.token = action.payload.token;
      })
      .addCase(loginAsync.rejected, (state, action) => {
        errorToast(action.payload as string);
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(currentUserAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        currentUserAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.isAuthenticated = true;
          state.user = action.payload.user;
        }
      )
      .addCase(currentUserAsync.rejected, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.error = action.payload as string;
      })
      .addCase(logoutAsync.fulfilled, (state) => {
        successToast("You have been logged out successfully. Goodbye!");
        state.isAuthenticated = false;
        state.user = null;
        state.token = null;
      });
  },
});

export default authSlice.reducer;
