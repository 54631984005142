import apiClient from "../../../api/apiClient";
import { Class, ClassSection } from "./classTypes";

//Base URL

const BASE_PATH = "/class";

export const fetchClassesOfSchool = async (
  schoolId: number,
  section: number | undefined,
  schoolSubDomain: string,
  groupSubDomain: string
): Promise<{ data: Class[]; message: string }> => {
  const response = await apiClient.get(
    `${BASE_PATH}/get-classes-of-school/${schoolId}?section=${section || 0}`,
    {
      headers: {
        Subdomain: schoolSubDomain || "",
        GroupSubDomain: groupSubDomain || "",
      },
    }
  );
  return response?.data;
};

export const fetchClassesSectionOfSchool = async (
  schoolId: number,
  section: number | undefined,
  schoolSubDomain: string,
  groupSubDomain: string
): Promise<{ data: ClassSection[]; message: string }> => {
  const response = await apiClient.get(
    `${BASE_PATH}/get-classes-of-school/${schoolId}?section=${section || 0}`,
    {
      headers: {
        Subdomain: schoolSubDomain || "",
        GroupSubDomain: groupSubDomain || "",
      },
    }
  );
  return response?.data;
};

// export const fetchClassesSectionOfSchool = async (
//   schoolId: number,
//   section: number | undefined
// ): Promise<{ data: ClassSection[]; message: string }> => {
//   const response = await apiClient.get(
//     `${BASE_PATH}/get-classes-of-school/${schoolId}?section=${section || 0}`
//   );
//   return response?.data;
// };

export const fetchClassSections = async (
  schoolId: number,
  classId: number,
  schoolSubDomain: string,
  groupSubDomain: string
): Promise<{ data: ClassSection[]; message: string }> => {
  const response = await apiClient.get(
    `${BASE_PATH}/get-sections-of-class/${schoolId}/${classId}`,
    {
      headers: {
        Subdomain: schoolSubDomain || "",
        GroupSubDomain: groupSubDomain || "",
      },
    }
  );
  return response?.data;
};

export const createClass = async (
  schoolId: number,
  newClass: Class,
  schoolSubDomain: string,
  groupSubDomain: string
): Promise<{ data: Class; message: string }> => {
  const response = await apiClient.post(
    `${BASE_PATH}/add-class-to-school/${schoolId}`,
    newClass,
    {
      headers: {
        Subdomain: schoolSubDomain || "",
        GroupSubDomain: groupSubDomain || "",
      },
    }
  );
  return response?.data;
};

export const updateClass = async (
  schoolId: number,
  classId: number,
  updatedClass: Class,
  schoolSubDomain: string,
  groupSubDomain: string
): Promise<{ data: Class; message: string }> => {
  const response = await apiClient.put(
    `${BASE_PATH}/update-class/${schoolId}/${classId}`,
    updatedClass,
    {
      headers: {
        Subdomain: schoolSubDomain || "",
        GroupSubDomain: groupSubDomain || "",
      },
    }
  );
  return response?.data;
};

export const deleteClass = async (
  schoolId: number,
  classId: number,
  schoolSubDomain: string,
  groupSubDomain: string
): Promise<void> => {
  await apiClient.delete(
    `${BASE_PATH}/delete-class-from-school/${schoolId}/${classId}`,
    {
      headers: {
        Subdomain: schoolSubDomain || "",
        GroupSubDomain: groupSubDomain || "",
      },
    }
  );
};

export const createClassSection = async (
  schoolId: number,
  classId: number,
  newSection: ClassSection,
  schoolSubDomain: string,
  groupSubDomain: string
): Promise<{ data: ClassSection; message: string }> => {
  const response = await apiClient.post(
    `${BASE_PATH}/add-section-to-class/${schoolId}/${classId}`,
    newSection,
    {
      headers: {
        Subdomain: schoolSubDomain || "",
        GroupSubDomain: groupSubDomain || "",
      },
    }
  );
  return response?.data;
};

export const updateClassSection = async (
  schoolId: number,
  classSectionId: number,
  updatedClassSection: ClassSection,
  schoolSubDomain: string,
  groupSubDomain: string
): Promise<{ data: ClassSection; message: string }> => {
  const response = await apiClient.put(
    `${BASE_PATH}/update-section/${schoolId}/${classSectionId}`,
    updatedClassSection,
    {
      headers: {
        Subdomain: schoolSubDomain || "",
        GroupSubDomain: groupSubDomain || "",
      },
    }
  );
  return response?.data;
};

export const deleteClassSection = async (
  schoolId: number,
  classSectionId: number,
  schoolSubDomain: string,
  groupSubDomain: string
): Promise<void> => {
  await apiClient.delete(
    `${BASE_PATH}/delete-section-from-class/${schoolId}/${classSectionId}`,
    {
      headers: {
        Subdomain: schoolSubDomain || "",
        GroupSubDomain: groupSubDomain || "",
      },
    }
  );
};
