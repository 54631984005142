import { Formik, ErrorMessage, Field } from "formik";
import { useEffect, useMemo, useRef } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../core/data/redux/hooks";
import { createSchoolGroupInitialValues } from "../initialValues";
import { createSchoolValidationSchema } from "../schema";
import PhoneInput from "react-phone-number-input";
import {
  createSchoolGroupAsync,
  updateSchoolGroupAsync,
  fetchSchoolGroupAsync,
} from "../../../../core/data/redux/features/systemAdmin/systemAdminSlice";
import { uploadMultipleFilesAsync } from "../../../../core/data/redux/features/systemAdmin/systemAdminSlice";
import { errorToast } from "../../../../core/common/toast/toast";
import UploadButton from "../../../../core/common/UploadButton";

const SchoolsGroupsFormModal = ({ selectedschoolgroup }: any) => {
  console.log(selectedschoolgroup, "selectedAcademicYear");
  const dispatch = useAppDispatch();
  // const schoolId = useAppSelector((state) => state.auth.user?.schoolId);
  const formRef = useRef(null);
  useEffect(() => {
    const modalElement = document.getElementById("add_schoolgroup");

    const handleModalClose = () => {
      if (formRef.current) {
        // @ts-ignore
        formRef.current.resetForm();
      }
    };

    modalElement?.addEventListener("hidden.bs.modal", handleModalClose);

    return () => {
      modalElement?.removeEventListener("hidden.bs.modal", handleModalClose);
    };
  }, []);

  const finalInitialValues = useMemo(() => {
    if (selectedschoolgroup) {
      return {
        schoolGroupName: selectedschoolgroup.schoolGroupName,
        schoolGroupDisplayName: selectedschoolgroup.schoolGroupDisplayName,
        schoolGroupAddress: selectedschoolgroup.schoolGroupAddress,
        contactPersonName: selectedschoolgroup.contactPersonName,
        contactPersonPhoneNo: selectedschoolgroup.contactPersonPhoneNo,
        contactPersonDesignation: selectedschoolgroup.contactPersonDesignation,
        groupSubDomain: selectedschoolgroup.groupSubDomain,
        groupLogo: selectedschoolgroup.groupLogo,
      };
    }
    return createSchoolGroupInitialValues;
  }, [selectedschoolgroup]);

  const handelfileupload = async (
    file: any,
    name: string,
    setFieldValue: any
  ) => {
    if (file) {
      const formData = new FormData();
      formData.append("files", file);

      try {
        const response = await dispatch(
          uploadMultipleFilesAsync({
            formData,
            fileCategory: "groupLogo",
          })
        ).unwrap();

        setFieldValue(name, response.uploadedFiles[0].fileUrl);
      } catch (error) {
        errorToast("Upload failed:");
      }
    }
  };
  return (
    <div className="modal fade" id="add_schoolgroup">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              {selectedschoolgroup ? "Edit School Group" : "Add School Group"}
            </h4>
            <button
              type="button"
              className="btn-close custom-btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="ti ti-x" />
            </button>
          </div>
          <Formik
            initialValues={finalInitialValues}
            enableReinitialize
            innerRef={formRef}
            validationSchema={createSchoolValidationSchema}
            onSubmit={async (values, actions) => {
              console.log(values);
              try {
                let result;
                if (selectedschoolgroup?.id) {
                  result = await dispatch(
                    updateSchoolGroupAsync({
                      groupId: selectedschoolgroup.id,
                      updatedSchoolGroup: values,
                    })
                  ).unwrap();
                } else {
                  result = await dispatch(
                    createSchoolGroupAsync({
                      newSchoolGroup: values,
                    })
                  ).unwrap();
                }
                console.log("Result:", result);
                if (result) {
                  document.getElementById("btn-cancel")?.click();
                  await dispatch(fetchSchoolGroupAsync()).unwrap();
                }
              } catch (error) {
                console.error("Error creating School group:", error);
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              setFieldTouched,
              values,
              resetForm,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">School Group Name</label>
                        <Field
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.schoolGroupName}
                          name="schoolGroupName"
                        />
                        <ErrorMessage
                          name="schoolGroupName"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          School Group Display Name
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.schoolGroupDisplayName}
                          name="schoolGroupDisplayName"
                        />
                        <ErrorMessage
                          name="schoolGroupDisplayName"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          School Group Address
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.schoolGroupAddress}
                          name="schoolGroupAddress"
                        />
                        <ErrorMessage
                          name="schoolGroupAddress"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          School Group SubDomain
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.groupSubDomain}
                          name="groupSubDomain"
                        />
                        <ErrorMessage
                          name="groupSubDomain"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">School Group Logo</label>
                        <Field
                          name="groupLogo"
                          component={UploadButton}
                          className="form-control"
                          buttonClassName="btn btn-primary btn-sm"
                          // buttonText="Upload School Logo"
                          image={values.groupLogo}
                          multiple={false}
                          onFileUpload={(file: any) =>
                            handelfileupload(file, "groupLogo", setFieldValue)
                          }
                          initialFileNames={
                            values.groupLogo ? ["School Group Logo"] : []
                          }
                          accept=".pdf,.png,.jpg,.jpeg"
                        />
                        <ErrorMessage
                          name="groupLogo"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Contact Person Name
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.contactPersonName}
                          name="contactPersonName"
                        />
                        <ErrorMessage
                          name="contactPersonName"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Contact Person Phone No
                        </label>
                        <PhoneInput
                          placeholder="Enter phone number"
                          className="form-control"
                          value={values.contactPersonPhoneNo}
                          name="contactPersonPhoneNo"
                          onChange={(value) =>
                            setFieldValue("contactPersonPhoneNo", value)
                          }
                        />
                        <ErrorMessage
                          name="contactPersonPhoneNo"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Contact Person Designation
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.contactPersonDesignation}
                          name="contactPersonDesignation"
                        />
                        <ErrorMessage
                          name="contactPersonDesignation"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <div
                    onClick={() => resetForm()}
                    id="btn-cancel"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isSubmitting}
                  >
                    {selectedschoolgroup
                      ? "Edit School Group"
                      : "Add School Group"}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default SchoolsGroupsFormModal;
