import { configureStore } from "@reduxjs/toolkit";
import themeSettingSlice from "./themeSettingSlice";
import sidebarSlice from "./sidebarSlice";
import classReducer from "./features/class/classSlice";
import authReducer from "./features/auth/authSlice";
import feeReducer from "./features/fee/feeSlice";
import utilsReducer from "./features/utils/utilsSlice";
import academicYearReducer from "./features/academicYear/academicYearSlice";
import admissionReducer from "./features/admission/admissionSlice";
import feeCategoryReducer from "./features/feeCategory/feeCategorySlice";
import SystemAdminReducer from "./features/systemAdmin/systemAdminSlice";


export const store = configureStore({
  reducer: {
    themeSetting: themeSettingSlice,
    sidebarSlice: sidebarSlice,
    class: classReducer,
    auth: authReducer,
    fee: feeReducer,
    utils: utilsReducer,
    academicYear: academicYearReducer,
    admission: admissionReducer,
    feeCategory:feeCategoryReducer,
    systemAdmin:SystemAdminReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;