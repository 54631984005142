// import { useState } from "react";
import { Formik, ErrorMessage, Field } from "formik";
import { useEffect, useMemo, useRef } from "react";
import { useAppDispatch } from "../../../../core/data/redux/hooks";
import { createFeeCategoryToSchoolInitailValues } from "../initialValues";
import { createSchoolsValidationSchema } from "../schema";
import {
  createFeeCategoryOfSchoolAsync,
  updateFeeCategoryOfSchoolAsync,
  fetchFeeCategoryOfSchoolAsync,
} from "../../../../core/data/redux/features/systemAdmin/systemAdminSlice";

const FeeCategorySchoolMoadl = ({ selectedschool, schoolId }: any) => {
  console.log(selectedschool, schoolId, "selectedschoolgroup");
  const dispatch = useAppDispatch();
  const formRef = useRef(null);
  useEffect(() => {
    const modalElement = document.getElementById("add_feeCategory_to_school");

    const handleModalClose = () => {
      if (formRef.current) {
        // @ts-ignore
        formRef.current.resetForm();
      }
    };

    modalElement?.addEventListener("hidden.bs.modal", handleModalClose);

    return () => {
      modalElement?.removeEventListener("hidden.bs.modal", handleModalClose);
    };
  }, []);

  const finalInitialValues = useMemo(() => {
    if (selectedschool) {
      return {
        feeCategoryName: selectedschool.feeCategoryName,
        feeCategoryDisplayName: selectedschool.feeCategoryDisplayName,
        feeCategoryDescription: selectedschool.feeCategoryDescription,
      };
    }
    return createFeeCategoryToSchoolInitailValues;
  }, [selectedschool]);

  return (
    <div className="modal fade" id="add_feeCategory_to_school">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              {selectedschool ? "Edit Fee Category" : "Add Fee Category"}
            </h4>
            <button
              type="button"
              className="btn-close custom-btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="ti ti-x" />
            </button>
          </div>
          <Formik
            initialValues={finalInitialValues}
            enableReinitialize
            innerRef={formRef}
            validationSchema={createSchoolsValidationSchema}
            onSubmit={async (values, actions) => {
              console.log("Form Values Before Submitting:", values);
              try {
                if (schoolId) {
                  if (selectedschool) {
                    const result = await dispatch(
                      updateFeeCategoryOfSchoolAsync({
                        schoolId: schoolId,
                        feecategoryId: selectedschool.id,
                        updatefeecategory: values,
                      })
                    ).unwrap();
                    if (result) {
                      document.getElementById("btn-cancel")?.click();
                      await dispatch(
                        fetchFeeCategoryOfSchoolAsync({
                          schoolId: Number(schoolId),
                        })
                      ).unwrap();
                    }
                    return null;
                  } else {
                    const result = await dispatch(
                      createFeeCategoryOfSchoolAsync({
                        schoolId: schoolId,
                        createfeecategory: values,
                      })
                    ).unwrap();
                    if (result) {
                      document.getElementById("btn-cancel")?.click();
                      await dispatch(
                        fetchFeeCategoryOfSchoolAsync({
                          schoolId: Number(schoolId),
                        })
                      ).unwrap();
                    }
                  }
                }
              } catch (error) {
                console.error("Error creating academic year:", error);
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              setFieldTouched,
              values,
              resetForm,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12 col-xxl-12">
                      <div className="mb-3">
                        <label className="form-label">Fee Category Name</label>
                        <Field
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.feeCategoryName}
                          name="feeCategoryName"
                        />
                        <ErrorMessage
                          name="feeCategoryName"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="col-md-12 col-xxl-12">
                      <div className="mb-3">
                        <label className="form-label">
                          Fee Category Display Name
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.feeCategoryDisplayName}
                          name="feeCategoryDisplayName"
                        />
                        <ErrorMessage
                          name="feeCategoryDisplayName"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="col-md-12 col-xxl-12">
                      <div className="mb-3">
                        <label className="form-label">
                          Fee Category Description
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.feeCategoryDescription}
                          name="feeCategoryDescription"
                        />
                        <ErrorMessage
                          name="feeCategoryDescription"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => resetForm()}
                    id="btn-cancel"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isSubmitting}
                  >
                    {selectedschool ? "Edit Fee Category" : "Add Fee Category"}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default FeeCategorySchoolMoadl;
