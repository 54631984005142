import { FeeCategory } from "./feeCategoryType";
import apiClient from "../../../api/apiClient";

//Base URL

const BASE_PATH = "/fee-category";

export const fetchFeeCategories = async (
  schoolId: number,
  schoolSubDomain: string,
  groupSubDomain: string
): Promise<{
  data: FeeCategory[];
  message: string;
}> => {
  const response = await apiClient.get(
    `${BASE_PATH}/get-fee-category-of-school/${schoolId}`,
    {
      headers: {
        Subdomain: schoolSubDomain || "",
        GroupSubDomain: groupSubDomain || "",
      },
    }
  );
  return response?.data;
};
