import { Formik, ErrorMessage, Field } from "formik";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../core/data/redux/hooks";
// import { createSchoolInitialValues } from "../initialValues";
import { createSchoolInitialValues } from "../initialValues";
// import { createSchooladminValidationSchema } from "../schema";
import { createSchooladminValidationSchema } from "../schema";
import PhoneInput from "react-phone-number-input";
import CommonSelect from "../../../../core/common/commonSelect";
import {
  createSchoolGroupAdminAsync,
  fetchSchoolByGroupIdAsync,
  fetchSchoolGroupAsync,
  fetchSchooladminAsync,
  updateSchoolGroupAdminAsync,
} from "../../../../core/data/redux/features/systemAdmin/systemAdminSlice";
import {
  createschoolAdminAsync,
  updateSchooladminAsync,
} from "../../../../core/data/redux/features/systemAdmin/systemAdminSlice";
import { useNavigate } from "react-router";
import { all_routes } from "../../../router/all_routes";
import Select from "react-select";
import { fetchAllSchoolAdminAsync } from "../../../../core/data/redux/features/systemAdmin/systemAdminSlice";
import { DatePicker } from "antd";
import dayjs from "dayjs";
// import { fetchAcademicYearsAsync } from "../../../../core/data/redux/features/academicYear/academicYearSlice";
const SchoolGroupAdminFormModal = ({ selectedschooladmin }: any) => {
  console.log(selectedschooladmin, "selectedAdmin");
  const navigate = useNavigate();
  const route = all_routes;
  const [groupId, setGroupId] = useState(null);
  const [schoolId, setSchoolId] = useState<number>(0);
  const dispatch = useAppDispatch();
  // const genderData = useAppSelector((state) => state.utils.genderData);
  const { schoolGroupData, schoolList } = useAppSelector(
    (state) => state.systemAdmin
  );
  // const schoolId = useAppSelector((state) => state.auth.user?.schoolId);
  const formRef = useRef(null);

  const handlegroupchange = (selectedOption: any) => {
    setGroupId(selectedOption?.value);
  };
  useEffect(() => {
    const modalElement = document.getElementById("add_schoolgroup");

    const handleModalClose = () => {
      if (formRef.current) {
        // @ts-ignore
        formRef.current.resetForm();
      }
    };
    modalElement?.addEventListener("hidden.bs.modal", handleModalClose);

    return () => {
      modalElement?.removeEventListener("hidden.bs.modal", handleModalClose);
    };
  }, []);

  useEffect(() => {
    dispatch(fetchSchoolGroupAsync());
    if (groupId) {
      dispatch(fetchSchoolByGroupIdAsync(groupId));
    }
  }, [dispatch, groupId, schoolId]);

  const genderDataOption = [
    { value: 1, label: "Male" },
    { value: 2, label: "Female" },
    { value: 3, label: "Other" },
    { ivalued: 4, label: "Prefer not to say" },
  ];

  const finalInitialValues = useMemo(() => {
    if (selectedschooladmin) {
      return {
        firstName: selectedschooladmin.firstName,
        middleName: selectedschooladmin.middleName,
        lastName: selectedschooladmin.lastName,
        email: selectedschooladmin.email,
        phoneNumber: selectedschooladmin.phoneNumber,
        genderId: selectedschooladmin.genderId,
        dob: selectedschooladmin.dob,
        aadharCardNumber: selectedschooladmin.aadharCardNumber,
      };
    }
    return createSchoolInitialValues;
  }, [selectedschooladmin]);

  const schoolOptions = schoolList.map((group: any) => ({
    value: group.id,
    label: group.schoolName,
  }));

  const schoolgroupsOptions = schoolGroupData.map((group: any) => ({
    value: group.id,
    label: group.schoolGroupName,
  }));

  return (
    <div className="modal fade" id="add_school_group_admin">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            {selectedschooladmin ? (
              <h4 className="modal-title">Edit School Group Admin</h4>
            ) : (
              <h4 className="modal-title">Add School Group Admin</h4>
            )}
            <button
              type="button"
              className="btn-close custom-btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="ti ti-x" />
            </button>
          </div>
          <Formik
            initialValues={finalInitialValues}
            enableReinitialize
            innerRef={formRef}
            validationSchema={createSchooladminValidationSchema}
            onSubmit={async (values, actions) => {
              console.log(values);
              try {
                let result;
                if (selectedschooladmin) {
                  result = await dispatch(
                    updateSchoolGroupAdminAsync({
                      schoolGroupId: selectedschooladmin.schoolGroupId,
                      schoolGroupAdminId:
                        selectedschooladmin.schoolGroupAdminId,
                      updatedSchoolGroupAdmin: values,
                    })
                  ).unwrap();
                } else {
                  result = await dispatch(
                    createSchoolGroupAdminAsync({
                      schoolGroupId: Number(groupId),
                      newSchoolGroupAdmin: values,
                    })
                  ).unwrap();
                }
                if (result) {
                  document.getElementById("btn-cancel")?.click();
                  await dispatch(fetchAllSchoolAdminAsync()).unwrap();
                }
              } catch (error) {
                console.error("Error creating academic year:", error);
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              setFieldTouched,
              values,
              resetForm,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="row">
                    {!selectedschooladmin && (
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Select School Group
                          </label>
                          <Select
                            options={schoolgroupsOptions}
                            onChange={handlegroupchange}
                            placeholder="Select School Group"
                            isSearchable={true}
                          />
                        </div>
                      </div>
                    )}

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">First Name</label>
                        <Field
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.firstName}
                          name="firstName"
                        />
                        <ErrorMessage
                          name="firstName"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Middle Name</label>
                        <Field
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.middleName}
                          name="middleName"
                        />
                        <ErrorMessage
                          name="middleName"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Last Name</label>
                        <Field
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.lastName}
                          name="lastName"
                        />
                        <ErrorMessage
                          name="lastName"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Email</label>
                        <Field
                          type="email"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          name="email"
                        />
                        <ErrorMessage
                          name="email"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Phone number</label>
                        <PhoneInput
                          placeholder="Enter phone number"
                          className="form-control"
                          value={values.phoneNumber}
                          name="contactPersonPhoneNo"
                          onChange={(value) =>
                            setFieldValue("phoneNumber", value)
                          }
                        />
                        <ErrorMessage
                          name="phoneNumber"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Gender</label>

                        <Field
                          component={CommonSelect}
                          options={genderDataOption}
                          name="genderId"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Date Of Birth</label>
                        <div className="input-icon position-relative">
                          <DatePicker
                            className="form-control datetimepicker"
                            format="YYYY-MM-DD"
                            onChange={(date: dayjs.Dayjs | null) =>
                              setFieldValue(
                                "dob",
                                date ? date.format("YYYY-MM-DD") : ""
                              )
                            }
                            onBlur={() => setFieldTouched("dob", true)}
                            value={
                              values.dob
                                ? dayjs(values.dob, "YYYY-MM-DD")
                                : null
                            }
                            name="dob"
                            placeholder="Select Date"
                          />

                          <span className="input-icon-addon">
                            <i className="ti ti-calendar" />
                          </span>
                        </div>
                        <ErrorMessage
                          name="dob"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Aadhar Card Number</label>
                        <Field
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.aadharCardNumber}
                          name="aadharCardNumber"
                        />
                        <ErrorMessage
                          name="aadharCardNumber"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <div
                    onClick={() => resetForm()}
                    id="btn-cancel"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isSubmitting}
                  >
                    {selectedschooladmin
                      ? "Edit School Group Admin"
                      : "Add School Group Admin"}
                    {/* Add School Group */}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default SchoolGroupAdminFormModal;
