import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../data/redux/hooks";
import CommonSelect, { Option } from "./commonSelect";

import {
  fetchSchoolByGroupIdAsync,
  fetchSchoolGroupAsync,
} from "../data/redux/features/systemAdmin/systemAdminSlice";

type SchoolSelectionProps = {
  setSchoolData: (data: any) => void;
  setGroupData: (data: any) => void;
};

const SchoolSelection: React.FC<SchoolSelectionProps> = ({
  setSchoolData,
  setGroupData,
}) => {
  const { schoolGroupData, schoolList } = useAppSelector(
    (state) => state.systemAdmin
  );
  const dispatch = useAppDispatch();
  const [selectedSchoolGroup, setSelectedSchoolGroup] = useState<
    string | number | null
  >(null);
  const [selectedSchool, setSelectedSchool] = useState<string | number | null>(
    null
  );

  const schoolOptions: Option[] = schoolList.map((group: any) => ({
    value: group.id,
    label: group.schoolName,
  }));

  const schoolgroupsOptions: Option[] = schoolGroupData.map((group: any) => ({
    value: group.id,
    label: group.schoolGroupName,
  }));

  useEffect(() => {
    dispatch(fetchSchoolGroupAsync());
  }, [dispatch]);

  // Create mock field and form objects for CommonSelect
  const createFieldAndForm = (
    name: string,
    value: any,
    onChange: (value: any) => void
  ) => {
    return {
      field: {
        name,
        value,
      },
      form: {
        setFieldValue: (_name: string, newValue: any) => onChange(newValue),
        setFieldTouched: () => {},
      },
    };
  };

  return (
    <div className="row d-flex justify-content-center">
      <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xxl-4 mb-3">
        <label className="form-label">
          1. Please Select The School Group First
        </label>
        <CommonSelect
          options={schoolgroupsOptions}
          {...createFieldAndForm(
            "schoolGroup",
            selectedSchoolGroup,
            (value) => {
              setSelectedSchoolGroup(value);
              if (value) {
                dispatch(fetchSchoolByGroupIdAsync(value));
              }
              setSchoolData(null);
              setSelectedSchool(null);
            }
          )}
          onValueSelect={(value) => {
            if (value) {
              setGroupData(
                schoolGroupData.find((group: any) => group.id === value)
              );
              dispatch(fetchSchoolByGroupIdAsync(value));
            } else {
              setGroupData(null);
            }
            setSchoolData(null);
          }}
        />
      </div>

      <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xxl-4 mb-3">
        <label className="form-label">2. Please Select The School</label>
        <CommonSelect
          options={schoolOptions}
          {...createFieldAndForm("school", selectedSchool, (value) => {
            setSelectedSchool(value);
          })}
          onValueSelect={(value) => {
            if (value) {
              setSchoolData(
                schoolList.find((school: any) => school.id === value)
              );
            } else {
              setSchoolData(null);
            }
          }}
        />
      </div>
    </div>
  );
};

export default SchoolSelection;
