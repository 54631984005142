import axios from "axios";

import {
  attachRequestInterceptor,
  //   attachResponseInterceptor,
} from "./interceptors";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api/v1",
  timeout: 15000,
});

attachRequestInterceptor(apiClient);
// attachResponseInterceptor(apiClient);

export default apiClient;
