import { Formik, ErrorMessage, Field } from "formik";
import { createAcademicYearInitialValues } from "./initialValue";
import { createAcademicYearValidationSchema } from "./schema";

import { useEffect, useMemo, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../core/data/redux/hooks";
import {
  createAcademicYearAsync,
  updateAcademicYearAsync,
  fetchAcademicYearsAsync,
} from "../../../core/data/redux/features/academicYear/academicYearSlice";
import { DatePicker } from "antd";
import dayjs from "dayjs";
const AcademicYearFormModal = ({ selectedAcademicYear, schoolId }: any) => {
  console.log(selectedAcademicYear);
  const dispatch = useAppDispatch();
  const formRef = useRef(null);
  useEffect(() => {
    const modalElement = document.getElementById("add_class");

    const handleModalClose = () => {
      if (formRef.current) {
        // @ts-ignore
        formRef.current.resetForm();
      }
    };

    modalElement?.addEventListener("hidden.bs.modal", handleModalClose);

    return () => {
      modalElement?.removeEventListener("hidden.bs.modal", handleModalClose);
    };
  }, []);

  const finalInitialValues = useMemo(() => {
    if (selectedAcademicYear) {
      return {
        academicYearName: selectedAcademicYear.academicYearName,
        academicYearDisplayName: selectedAcademicYear.academicYearDisplayName,
        startDate: dayjs(selectedAcademicYear.startDate),
        endDate: dayjs(selectedAcademicYear.endDate),
      };
    }
    return createAcademicYearInitialValues;
  }, [selectedAcademicYear]);

  return (
    <div className="modal fade" id="add_class">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              {selectedAcademicYear
                ? "Edit Academic Year"
                : "Add Academic Year"}
            </h4>
            <button
              type="button"
              className="btn-close custom-btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="ti ti-x" />
            </button>
          </div>
          <Formik
            initialValues={finalInitialValues}
            enableReinitialize
            innerRef={formRef}
            validationSchema={createAcademicYearValidationSchema}
            onSubmit={async (values, actions) => {
              try {
                if (schoolId) {
                  const startYear = dayjs(values.startDate).year();
                  const endYear = startYear + 1;
                  const academicYearName = `${startYear}-${endYear}`;
                  const academicYearDisplayName = `${startYear}-${endYear}`;
                  const formattedValues = {
                    ...values,
                    startDate: dayjs(values.startDate).format("YYYY-MM-DD"),
                    endDate: dayjs(values.startDate)
                      .add(1, "year")
                      .subtract(1, "day")
                      .format("YYYY-MM-DD"),
                    academicYearName: academicYearName,
                    academicYearDisplayName: academicYearDisplayName,
                  };
                  console.log(formattedValues, "formattedValues");
                  let result;
                  if (selectedAcademicYear) {
                    result = await dispatch(
                      updateAcademicYearAsync({
                        schoolId,
                        academicYearId: selectedAcademicYear.id,
                        academicYearData: formattedValues,
                      })
                    ).unwrap();
                  } else {
                    result = await dispatch(
                      createAcademicYearAsync({
                        schoolId,
                        newAcademicYear: formattedValues,
                      })
                    ).unwrap();
                  }

                  if (result) {
                    document.getElementById("btn-cancel")?.click();
                     await dispatch(fetchAcademicYearsAsync(schoolId));
                  }
                }
              } catch (error) {
                console.error("Error creating academic year:", error);
              }
            }}
          >
            {({
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              values,
              resetForm,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Start Date</label>
                        <div className="input-icon position-relative">
                          <DatePicker
                            className="form-control datetimepicker"
                            format={{ format: "YYYY-MM-DD", type: "mask" }}
                            onChange={async (date: dayjs.Dayjs) => {
                              if (date) {
                                const endDate = dayjs(date)
                                  .add(1, "year")
                                  .subtract(1, "day")
                                  .startOf("day");

                                await setFieldValue("startDate", date);
                                await setFieldValue("endDate", endDate);
                                await setFieldValue(
                                  "academicYearName",
                                  `${date.year()}-${date.year() + 1}`
                                );
                                await setFieldValue(
                                  "academicYearDisplayName",
                                  `${date.year()}-${date.year() + 1}`
                                );
                              } else {
                                await setFieldValue("academicYearName", "");
                                await setFieldValue(
                                  "academicYearDisplayName",
                                  ""
                                );
                                await setFieldValue("startDate", null);
                                await setFieldValue("endDate", null);
                              }
                            }}
                            onBlur={() => setFieldTouched("startDate", true)}
                            value={values.startDate}
                            name="startDate"
                            placeholder="Select Start Date"
                          />

                          <span className="input-icon-addon">
                            <i className="ti ti-calendar" />
                          </span>
                        </div>
                        <ErrorMessage
                          name="startDate"
                          className="text-danger"
                          component="div"
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">End Date</label>
                        <div className="input-icon position-relative">
                          <DatePicker
                            disabled={true}
                            className="form-control datetimepicker"
                            format={{ format: "YYYY-MM-DD", type: "mask" }}
                            value={values.endDate}
                            name="endDate"
                          />
                          <span className="input-icon-addon">
                            <i className="ti ti-calendar" />
                          </span>
                        </div>
                        <ErrorMessage
                          name="endDate"
                          className="text-danger"
                          component="div"
                        />
                      </div>

                      {/* <div className="mb-3">
                        <label className="form-label">
                          Contact Person Designation
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          disabled={true}
                          value={values.academicYearName}
                          name="academicYearName"
                        />
                        <ErrorMessage
                          name="contactPersonDesignation"
                          className="text-danger"
                          component="div"
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">
                          academicYearDisplayName
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          disabled={true}
                          value={values.academicYearDisplayName}
                          name="academicYearDisplayName"
                        />
                        <ErrorMessage
                          name="contactPersonDesignation"
                          className="text-danger"
                          component="div"
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div
                    onClick={() => resetForm()}
                    id="btn-cancel"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isSubmitting}
                  >
                    {selectedAcademicYear
                      ? "Edit Academic Year"
                      : "Add Academic Year"}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default AcademicYearFormModal;
