import { useEffect, useState } from "react";
// import { classSection } from "../../../core/data/json/class-section";
import Table from "../../../core/common/dataTable/index";
import SchoolSelection from "../../../core/common/SchoolSelection";
import { Link } from "react-router-dom";
import ClassSectionFormModal from "./class-section-form-modal";
import DeleteConfirmationModal from "../../../core/common/delete-confirmation-modal";
import { all_routes } from "../../router/all_routes";
import {
  deleteClassSectionAsync,
  fetchClassesSectionsAsync,
} from "../../../core/data/redux/features/class/classSlice";
import { useAppDispatch, useAppSelector } from "../../../core/data/redux/hooks";
import { TableData } from "../../../core/data/interface";
import { ClassSection } from "../../../core/data/redux/features/class/classTypes";

interface SchoolData {
  id: number;
  schoolSubDomain: string;
  // add other properties if needed
}
interface GroupData {
  [key: string]: string;
  // add other properties if needed
}

const ClassSections = () => {
  const [selectedClassSection, setSelectedClassSection] =
    useState<ClassSection | null>(null);
  const { classSectionData, fetchLoading } = useAppSelector(
    (state) => state.class
  );
  const dispatch = useAppDispatch();
  const [schoolData, setSchoolData] = useState<SchoolData | null>(null);
  const [groupData, setGroupData] = useState<GroupData | null>(null);
  // const schoolId = useAppSelector((state) => state.auth.user?.schoolId);

  useEffect(() => {
    if (schoolData && groupData?.groupSubDomain) {
      dispatch(
        fetchClassesSectionsAsync({
          schoolId: schoolData?.id,
          section: 1,
          schoolSubDomain: schoolData?.schoolSubDomain,
          groupSubDomain: groupData?.groupSubDomain,
        })
      );
    }
  }, [dispatch, schoolData]);

  console.log(classSectionData, schoolData, "classSectionData");

  const route = all_routes;

  const columns = [
    {
      title: "Class",
      dataIndex: "className",
      sorter: (a: TableData, b: TableData) =>
        a.className.length - b.className.length,
    },
    {
      title: "Section",
      dataIndex: "sectionName",
      sorter: (a: TableData, b: TableData) =>
        a.sectionName.length - b.sectionName.length,
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: any) => (
        <div className="d-flex align-items-center">
          <div className="dropdown">
            <Link
              to="#"
              className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="ti ti-dots-vertical fs-14" />
            </Link>
            <ul className="dropdown-menu dropdown-menu-right p-3">
              <li>
                <div
                  className="dropdown-item rounded-1"
                  onClick={() => setSelectedClassSection(record)}
                  data-bs-toggle="modal"
                  data-bs-target="#add_class_section"
                >
                  <i className="ti ti-edit-circle me-2" /> Edit
                </div>
              </li>
              <li>
                <div
                  className="dropdown-item rounded-1"
                  onClick={() => setSelectedClassSection(record)}
                  data-bs-toggle="modal"
                  data-bs-target="#delete-modal"
                >
                  <i className="ti ti-trash-x me-2" /> Delete
                </div>
              </li>
            </ul>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content border">
        <SchoolSelection
          setSchoolData={setSchoolData}
          setGroupData={setGroupData}
        />
      </div>

      {schoolData?.id && (
        // <div className="page-wrapper">
        <div className="content">
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Section List</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={route.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Academic</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Sections
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <div
                onClick={() => setSelectedClassSection(null)}
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#add_class_section"
              >
                <i className="ti ti-square-rounded-plus-filled me-2" /> Add
                Section
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">Sections List</h4>
            </div>
            <div className="card-body p-0 py-3">
              <Table
                columns={columns}
                dataSource={classSectionData}
                loading={fetchLoading}
              />
            </div>
          </div>
        </div>
        // </div>
      )}

      <ClassSectionFormModal
        selectedClassSection={selectedClassSection}
        schoolData={schoolData}
        groupData={groupData}
      />

      <DeleteConfirmationModal
        onConfirm={async () => {
          if (schoolData && selectedClassSection && groupData?.groupSubDomain) {
            const result = await dispatch(
              deleteClassSectionAsync({
                schoolId: schoolData?.id,
                classSectionId: Number(selectedClassSection?.classSectionId),
                schoolSubDomain: schoolData?.schoolSubDomain,
                groupSubDomain: groupData.groupSubDomain,
              })
            );
            if (result) {
              const cancelButton = document.getElementById(
                "delete-cancel"
              ) as HTMLElement;
              cancelButton.click();
              setSelectedClassSection(null);
              dispatch(
                fetchClassesSectionsAsync({
                  schoolId: schoolData.id,
                  section: 1,
                  schoolSubDomain: schoolData.schoolSubDomain,
                  groupSubDomain: groupData.groupSubDomain,
                })
              ).unwrap();
            }
          }
        }}
      />
    </div>
  );
};

export default ClassSections;
