import React, { useEffect, useRef, useState } from "react";
import { all_routes } from "../../router/all_routes";
import { Link } from "react-router-dom";
import PredefinedDateRanges from "../../../core/common/datePicker";
import CommonSelect from "../../../core/common/commonSelect";
import {
  allSection,
  cast,
  gender,
} from "../../../core/common/selectoption/selectoption";
import { TableData } from "../../../core/data/interface";
import Table from "../../../core/common/dataTable/index";
import AssignModal from "./assignModal";
// import { assignFeesData } from "../../../core/data/json/assignFeesData";
import TooltipOption from "../../../core/common/tooltipOption";
import { useAppDispatch, useAppSelector } from "../../../core/data/redux/hooks";
import {
  deleteFeeAsync,
  fetchFeesByCriteriaAsync,
} from "../../../core/data/redux/features/fee/feeSlice";
import FeeFormModal from "./feeFormModal";
import DeleteConfirmationModal from "../../../core/common/delete-confirmation-modal";
import SchoolSelection from "../../../core/common/SchoolSelection";
import { fetchAcademicYearsAsync } from "../../../core/data/redux/features/academicYear/academicYearSlice";
// import { useActionPermission } from "../../router/useActionPermission";
// import { ACTIONS } from "../../../core/data/constants";
interface SchoolData {
  id: number;
  schoolSubDomain: string;
  // add other properties if needed
}
interface GroupData {
  id: number;
  groupSubDomain: string;
  // add other properties if needed
}
const FeesAssign = () => {
  const routes = all_routes;
  const dispatch = useAppDispatch();
  const [selectedFee, setSelectedFee] = useState<TableData | null>(null);
  const [selectedAcademicYear, setSelectedAcademicYear] = useState<
    number | null
  >(null);
  // const schoolId = useAppSelector((state) => state.auth.user?.schoolId);
  const [schoolData, setSchoolData] = useState<SchoolData | null>(null);
  const [groupData, setGroupData] = useState<GroupData | null>(null);
  const { feesData, fetchfeeLoading } = useAppSelector((state) => state.fee);
  // const { canPerform } = useActionPermission();
  const { academicYearData } = useAppSelector((state) => state.academicYear);
  console.log(feesData);

  // const globalAcademicYearId = useAppSelector(
  //   (state) => state.academicYear.globalAcedemicYearId
  // );

  const { academicYearOptions } = React.useMemo(
    () => ({
      academicYearOptions:
        academicYearData?.map((item: any) => ({
          value: item.id,
          label: item.academicYearName,
        })) || [],
    }),
    [academicYearData]
  );

  const handleAcademicYearChange = (value: any) => {
    setSelectedAcademicYear(value);
  };

  useEffect(() => {
    if (schoolData && selectedAcademicYear && groupData?.groupSubDomain) {
      dispatch(
        fetchFeesByCriteriaAsync({
          schoolId: schoolData.id,
          criteria: {
            academicYearId: selectedAcademicYear,
          },
          schoolSubDomain: schoolData.schoolSubDomain,
          groupSubDomain: groupData.groupSubDomain,
        })
      );
    }
    if (schoolData) {
      dispatch(fetchAcademicYearsAsync(schoolData.id));
    }
  }, [selectedAcademicYear, schoolData, dispatch]);

  // console.log(feesData,"feesData");

  const columns = [
    {
      title: "Academic Year",
      dataIndex: "academicYearName",
      sorter: (a: TableData, b: TableData) =>
        a.className.length - b.className.length,
    },
    {
      title: "Class",
      dataIndex: "className",
      sorter: (a: TableData, b: TableData) =>
        a.className.length - b.className.length,
    },
    {
      title: "Fee Category",
      dataIndex: "feeCategoryName",
      sorter: (a: TableData, b: TableData) =>
        a.feeCategoryName.length - b.feeCategoryName.length,
    },

    {
      title: "Fee Category Frequency",
      dataIndex: "feeCategoryFrequencyName",
      sorter: (a: TableData, b: TableData) =>
        a.feeCategoryFrequencyName.length - b.feeCategoryFrequencyName.length,
    },
    {
      title: "Currency",
      dataIndex: "CurrencyISOCode",
      sorter: (a: TableData, b: TableData) =>
        a.CurrencyISOCode.length - b.CurrencyISOCode.length,
    },
    {
      title: "Fee Amount",
      dataIndex: "feeAmount",
      sorter: (a: TableData, b: TableData) => a.feeAmount - b.feeAmount,
    },
    // {
    //   title: "Discount Type",
    //   dataIndex: "feeDiscountName",
    //   sorter: (a: TableData, b: TableData) =>
    //     (a.feeDiscountName?.length || 0) - (b.feeDiscountName?.length || 0),
    // },
    // {
    //   title: "Discount Percentage",
    //   dataIndex: "discountPercentage",
    //   sorter: (a: TableData, b: TableData) =>
    //     (a.discountPercentage || 0) - (b.discountPercentage || 0),
    // },
    // {
    //   title: "Discount Remarks",
    //   dataIndex: "discountRemarks",
    //   sorter: (a: TableData, b: TableData) =>
    //     (a.discountRemarks?.length || 0) - (b.discountRemarks?.length || 0),
    // },

    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: any) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <div className="dropdown">
                <Link
                  to="#"
                  className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="ti ti-dots-vertical fs-14" />
                </Link>
                <ul className="dropdown-menu dropdown-menu-right p-3">
                  {/* {canPerform(ACTIONS.EDIT_FEE_CATALOG) ? ( */}
                  <li>
                    <div
                      className="dropdown-item rounded-1"
                      data-bs-toggle="modal"
                      data-bs-target="#add_fee"
                      onClick={() => {
                        setSelectedFee(record);
                      }}
                    >
                      <i className="ti ti-edit-circle me-2" />
                      Edit
                    </div>
                  </li>
                  {/* ) : (
                    <p className="text-danger fs-md d-flex align-items-center">
                      <i className="ti ti-lock me-2" /> Access Denied
                    </p>
                  )} */}
                  {/* {canPerform(ACTIONS.DELETE_FEE_CATALOG) ? ( */}
                  <li>
                    <div
                      className="dropdown-item rounded-1"
                      onClick={() => setSelectedFee(record)}
                      data-bs-toggle="modal"
                      data-bs-target="#delete-modal"
                    >
                      <i className="ti ti-trash-x me-2" />
                      Delete
                    </div>
                  </li>
                  {/* ) : (
                    <p className="text-danger fs-md d-flex align-items-center">
                      <i className="ti ti-lock me-2" /> Access Denied
                    </p>
                  )} */}
                </ul>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content border">
          <SchoolSelection
            setSchoolData={setSchoolData}
            setGroupData={setGroupData}
          />
        </div>
        {schoolData && schoolData.id && (
          <div className="row d-flex justify-content-center mt-2">
            <div className="col-md-4">
              <div className="mb-3">
                <label className="form-label">
                  {" "}
                  3. Please Select The Academic Year First
                </label>
                <CommonSelect
                  field={{
                    name: "academicYear",
                    value: selectedAcademicYear,
                  }}
                  form={{
                    setFieldValue: (_: string, value: number | null) =>
                      handleAcademicYearChange(value),
                    setFieldTouched: () => {},
                  }}
                  options={academicYearOptions}
                  className="w-100"
                />
              </div>
            </div>
          </div>
        )}

        {selectedAcademicYear && schoolData && schoolData.id && (
          <div className="content">
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
              <div className="my-auto mb-2">
                <h3 className="page-title mb-1">Fees Collection</h3>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to={routes.adminDashboard}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="#">Fees Collection</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Fee Catalog
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
                {/* <TooltipOption /> */}
                <div className="mb-2">
                  {/* {canPerform(ACTIONS.ADD_FEE_CATALOG) && ( */}
                  <div
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#add_fee"
                    onClick={() => setSelectedFee(null)}
                  >
                    <i className="ti ti-square-rounded-plus me-2" />
                    Add to Fee Catalog
                  </div>
                  {/* )} */}
                </div>
              </div>
            </div>
            {/* /Page Header */}
            {/* Students List */}
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                <h4 className="mb-3">Fee Catalog</h4>
                <div className="d-flex align-items-center flex-wrap"></div>
              </div>
              <div className="card-body p-0 py-3">
                {/* Student List */}
                <Table
                  dataSource={feesData}
                  columns={columns}
                  loading={fetchfeeLoading}
                />
                {/* /Student List */}
              </div>
            </div>
            {/* /Students List */}
          </div>
        )}
      </div>
      {/* /Page Wrapper */}
      <FeeFormModal
        selectedFee={selectedFee}
        schoolData={schoolData}
        selectedAcademicYear1={selectedAcademicYear}
        groupData={groupData}
      />
      <DeleteConfirmationModal
        onConfirm={async () => {
          try {
            if (selectedFee && schoolData && groupData?.groupSubDomain) {
              const result = await dispatch(
                deleteFeeAsync({
                  schoolId: schoolData.id,
                  feeId: selectedFee.id,
                  schoolSubDomain: schoolData?.schoolSubDomain,
                  groupSubDomain: groupData.groupSubDomain,
                })
              ).unwrap();

              if (result) {
                if (
                  schoolData &&
                  selectedAcademicYear &&
                  groupData?.groupSubDomain
                ) {
                  let resultData = await dispatch(
                    fetchFeesByCriteriaAsync({
                      schoolId: schoolData.id,
                      criteria: { academicYearId: selectedAcademicYear },
                      schoolSubDomain: schoolData?.schoolSubDomain,
                      groupSubDomain: groupData.groupSubDomain,
                    })
                  );
                  if (resultData) {
                    const cancelButton = document.getElementById(
                      "delete-cancel"
                    ) as HTMLElement;
                    cancelButton.click();
                    setSelectedFee(null);
                  }
                }
              }
            }
          } catch (error) {
            console.error("Error deleting fee:", error);
          }
        }}
      />
      {/* <AssignModal
        setEditModal={setEditModal}
        editModal={editModal}
        setAddModal={setAddModal}
        addModal={addModal}
      /> */}
    </>
  );
};

export default FeesAssign;
