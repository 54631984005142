import { useState } from "react";
import { Formik, ErrorMessage, Field, FieldArray } from "formik";
import { useEffect, useMemo, useRef } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../core/data/redux/hooks";
import { createReminderSettingsInitialValues } from "../initialValues";
import { createReminderSettingsSchema } from "../schema";
import { errorToast } from "../../../../core/common/toast/toast";

import {
  createReminderSettingOfSchoolAsync,
  createReminderTypesAsync,
  fetchReminderSettingOfSchoolAsync,
  updateReminderSettingOfSchoolAsync,
  updateReminderTypesAsync,
} from "../../../../core/data/redux/features/systemAdmin/systemAdminSlice";
import { fetchReminderTypesAsync } from "../../../../core/data/redux/features/systemAdmin/systemAdminSlice";
import CommonSelect from "../../../../core/common/commonSelect";
import { label } from "yet-another-react-lightbox/*";

const RemainderSettingsFormModal = ({
  selectedReminderSetting,
  schoolId,
}: any) => {
  console.log(selectedReminderSetting, "selectedschoolgroup");
  const dispatch = useAppDispatch();
  const {
    remainderTypeData,
    remainderSettingsData,
    remainderSettingsByIdData,
    fetchLoading,
    error,
  } = useAppSelector((state) => state.systemAdmin);
  const formRef = useRef(null);
  // const [freq, setFreq] = useState("");
  useEffect(() => {
    const modalElement = document.getElementById(
      "add_remainder_types_settings"
    );

    const handleModalClose = () => {
      if (formRef.current) {
        // @ts-ignore
        formRef.current.resetForm();
      }
    };

    modalElement?.addEventListener("hidden.bs.modal", handleModalClose);

    return () => {
      modalElement?.removeEventListener("hidden.bs.modal", handleModalClose);
    };
  }, []);

  const finalInitialValues = useMemo(() => {
    if (selectedReminderSetting) {
      const fakefreq = selectedReminderSetting.frequency
        .map((value: any) => {
          if (value % 24 === 0 && value % 168 !== 0 && value % 720 !== 0) {
            return { frequency: value / 24, unitType: "Day" };
          } else if (value % 168 === 0 && value % 720 !== 0) {
            return { frequency: value / 168, unitType: "Week" };
          } else if (value % 720 === 0) {
            return { frequency: value / 720, unitType: "Month" };
          }
          return null;
        })
        .filter(Boolean);

      return {
        freqfake: fakefreq,
        isActive: selectedReminderSetting.isActive,
        frequency: selectedReminderSetting.frequency,
        reminderTypeId: selectedReminderSetting.reminderTypeId,
        toCCBCCSettings: selectedReminderSetting.toCCBCCSettings,
      };
    }
    return {
      ...createReminderSettingsInitialValues,
    };
  }, [selectedReminderSetting]);

  const remaindertypesOptions = remainderTypeData.map((remainder: any) => ({
    value: remainder.id,
    label: remainder.type,
  }));

  const recipientMapping = {
    student: 1,
    parent: 2,
    // admin: 3,
    teacher: 4,
    schoolAdmin: 5,
  };
  const tocc = {
    student: 1,
    parent: 2,
    teacher: 4,
    schoolAdmin: 5,
  };

  const unitOptions = [
    { value: "Day", label: "Day" },
    { value: "Week", label: "Week" },
    { value: "Month", label: "Month" },
  ];
  useEffect(() => {
    dispatch(fetchReminderTypesAsync());
  }, [dispatch]);

  console.log(remaindertypesOptions, "remaindertypesOptions");

  return (
    <div className="modal fade" id="add_remainder_types_settings">
      <div className="modal-dialog modal-dialog-centered ">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              {selectedReminderSetting
                ? "Edit Remainder Settings"
                : "Add Remainder Settings"}
            </h4>
            <button
              type="button"
              className="btn-close custom-btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="ti ti-x" />
            </button>
          </div>
          <Formik
            initialValues={finalInitialValues}
            enableReinitialize
            innerRef={formRef}
            validationSchema={createReminderSettingsSchema}
            onSubmit={async (values, actions) => {
              console.log("Form Values Before Submitting:", values);
              try {
                let result;
                if (selectedReminderSetting) {
                  const { freqfake, ...newValues } = values;
                  result = await dispatch(
                    updateReminderSettingOfSchoolAsync({
                      schoolId: selectedReminderSetting.schoolId,
                      id: selectedReminderSetting.id,
                      updateremaindersettings: newValues,
                    })
                  ).unwrap();
                } else {
                  const { freqfake, ...newValues } = values;
                  result = await dispatch(
                    createReminderSettingOfSchoolAsync({
                      schoolId: schoolId,
                      createremaindersettings: newValues,
                    })
                  ).unwrap();
                }
                if (result) {
                  document.getElementById("btn-cancel")?.click();
                  await dispatch(
                    fetchReminderSettingOfSchoolAsync({ schoolId: schoolId })
                  );
                }
              } catch (error) {
                console.error("Error creating remainder types:", error);
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              setFieldTouched,
              values,
              resetForm,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="container">
                    {/* Reminder Type and Active Checkbox */}
                    <div className="row mb-4 d-flex align-items-center">
                      <div className="col-md-6">
                        <label className="form-label fw-bold">
                          Select Reminder Type
                        </label>
                        <Field
                          type="select"
                          name="reminderTypeId"
                          component={CommonSelect}
                          options={remaindertypesOptions}
                          value={values.reminderTypeId}
                        />
                        <ErrorMessage
                          name="reminderTypeId"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="col-md-6 d-flex align-items-center mt-3 mt-md-0">
                        <Field
                          type="checkbox"
                          name="isActive"
                          className="form-check-input me-2"
                          id="isActive"
                          checked={values.isActive}
                          onChange={(e: any) =>
                            setFieldValue("isActive", e.target.checked)
                          }
                        />
                        <label
                          htmlFor="isActive"
                          className="form-check-label fw-bold"
                        >
                          Active
                        </label>
                      </div>
                    </div>

                    {values.freqfake && (
                      <div className="mb-4">
                        <label className="form-label fw-bold">Frequency</label>
                        <FieldArray name="freqfake">
                          {({ push, remove }) => (
                            <div>
                              {values.freqfake.map(
                                (frequency: any, index: any) => (
                                  <div
                                    key={index}
                                    className=" mb-2 d-flex align-items-center gap-2"
                                  >
                                    <div>
                                      <label
                                        htmlFor=""
                                        className="text-dark mb-2"
                                      >
                                        Frequency
                                      </label>
                                      <Field
                                        type="number"
                                        className="form-control p-1"
                                        name={`freqfake.${index}.frequency`}
                                        style={{ width: "100px" }}
                                      />
                                    </div>
                                    <div>
                                      <label
                                        htmlFor=""
                                        className="text-dark mb-2"
                                      >
                                        Unit
                                      </label>

                                      <Field
                                        type="select"
                                        name={`freqfake.${index}.unitType`}
                                        // name="reminderTypeId"
                                        component={CommonSelect}
                                        options={unitOptions}
                                        value={`freqfake.${index}.unitType`}
                                        onValueSelect={(e: any) => {
                                          console.log(e);
                                          setFieldValue(
                                            `freqfake.${index}.unitType`,
                                            e
                                          );

                                          if (e === "Day") {
                                            setFieldValue(
                                              `frequency.${index}`,
                                              Number(frequency.frequency * 24)
                                            );
                                          }
                                          if (e === "Week") {
                                            setFieldValue(
                                              `frequency.${index}`,
                                              Number(frequency.frequency * 168)
                                            );
                                          }
                                          if (e === "Month") {
                                            setFieldValue(
                                              `frequency.${index}`,
                                              Number(frequency.frequency * 720)
                                            );
                                          }
                                        }}
                                      />
                                    </div>

                                    <div className="d-flex align-items-center mt-4">
                                      <p>
                                        {remaindertypesOptions.find(
                                          (option: any) =>
                                            option.value ===
                                            values.reminderTypeId
                                        )?.label ||
                                          "Please Select The Remainder Type"}
                                      </p>
                                    </div>

                                    <span
                                      className="mt-4"
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => remove(index)}
                                    >
                                      <i className="ti ti-x" />
                                    </span>
                                  </div>
                                )
                              )}
                              <button
                                type="button"
                                style={{
                                  width: "30px",
                                }}
                                className="btn btn-primary btn-sm"
                                onClick={() =>
                                  push({
                                    frequency: 0,
                                    unitType: "",
                                  })
                                }
                              >
                                +
                              </button>
                            </div>
                          )}
                        </FieldArray>
                      </div>
                    )}

                    {["toSetting"].map((setting) => (
                      <div className="mb-4" key={setting}>
                        <label className="form-label fw-bold text-capitalize">
                          {setting.replace("Setting", "")}
                        </label>
                        <div className="d-flex flex-wrap gap-3">
                          {Object.keys(tocc).map((key) => (
                            <div key={key} className="form-check">
                              <input
                                type="checkbox"
                                id={`${setting}-${key}`}
                                className="form-check-input"
                                checked={values.toCCBCCSettings[setting][key]}
                                onChange={(e) =>
                                  setFieldValue(
                                    `toCCBCCSettings.${setting}.${key}`,
                                    e.target.checked
                                  )
                                }
                              />
                              <label
                                htmlFor={`${setting}-${key}`}
                                className="form-check-label"
                              >
                                {key.charAt(0).toUpperCase() + key.slice(1)}
                              </label>
                            </div>
                          ))}

                          {/* Other Checkbox */}
                          <div className="form-check">
                            <Field
                              type="checkbox"
                              id={`${setting}-other`}
                              className="form-check-input"
                              checked={
                                values.toCCBCCSettings[setting].other.length > 0
                              }
                              onChange={(e: any) =>
                                setFieldValue(
                                  `toCCBCCSettings.${setting}.other`,
                                  e.target.checked ? [""] : []
                                )
                              }
                            />
                            <label
                              htmlFor={`${setting}-other`}
                              className="form-check-label"
                            >
                              Other
                            </label>
                          </div>
                        </div>

                        {/* Other Email Inputs */}
                        {values.toCCBCCSettings[setting].other.length > 0 && (
                          <FieldArray name={`toCCBCCSettings.${setting}.other`}>
                            {({ push, remove }) => (
                              <div className="mt-0 row d-flex g-2">
                                {values.toCCBCCSettings[setting].other.map(
                                  (email: any, index: any) => (
                                    <div
                                      key={index}
                                      className="input-group border mb-0 d-flex gap-1 rounded-2"
                                      // style={{ height: "5px" }}
                                    >
                                      <Field
                                        type="email"
                                        className="form-control border-0 fs-12 p-0"
                                        placeholder="Enter email"
                                        value={email}
                                        onChange={(e: any) =>
                                          setFieldValue(
                                            `toCCBCCSettings.${setting}.other.${index}`,
                                            e.target.value
                                          )
                                        }
                                      />
                                      <span
                                        className="d-flex align-items-center p-0"
                                        style={{
                                          color: "red",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => remove(index)}
                                      >
                                        <i className="ti ti-x" />
                                      </span>
                                    </div>
                                  )
                                )}
                                <div>
                                  <button
                                    type="button"
                                    style={{
                                      width: "30px",
                                    }}
                                    className="btn btn-primary btn-sm"
                                    onClick={() => push("")}
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                            )}
                          </FieldArray>
                        )}
                      </div>
                    ))}

                    {/* Recipient Settings (toSetting, ccSetting, bccSetting) */}
                    {["ccSetting", "bccSetting"].map((setting) => (
                      <div className="mb-4" key={setting}>
                        <label className="form-label fw-bold text-capitalize">
                          {setting.replace("Setting", "")}
                        </label>
                        <div className="d-flex flex-wrap gap-3">
                          {Object.keys(recipientMapping).map((key) => (
                            <div key={key} className="form-check">
                              <input
                                type="checkbox"
                                id={`${setting}-${key}`}
                                className="form-check-input"
                                checked={values.toCCBCCSettings[setting][key]}
                                onChange={(e) =>
                                  setFieldValue(
                                    `toCCBCCSettings.${setting}.${key}`,
                                    e.target.checked
                                  )
                                }
                              />
                              <label
                                htmlFor={`${setting}-${key}`}
                                className="form-check-label"
                              >
                                {key.charAt(0).toUpperCase() + key.slice(1)}
                              </label>
                            </div>
                          ))}

                          {/* Other Checkbox */}
                          <div className="form-check">
                            <Field
                              type="checkbox"
                              id={`${setting}-other`}
                              className="form-check-input"
                              checked={
                                values.toCCBCCSettings[setting].other.length > 0
                              }
                              onChange={(e: any) =>
                                setFieldValue(
                                  `toCCBCCSettings.${setting}.other`,
                                  e.target.checked ? [""] : []
                                )
                              }
                            />
                            <label
                              htmlFor={`${setting}-other`}
                              className="form-check-label"
                            >
                              Other
                            </label>
                          </div>
                        </div>

                        {/* Other Email Inputs */}
                        {values.toCCBCCSettings[setting].other.length > 0 && (
                          <FieldArray name={`toCCBCCSettings.${setting}.other`}>
                            {({ push, remove }) => (
                              <div className="mt-0 row d-flex g-2">
                                {values.toCCBCCSettings[setting].other.map(
                                  (email: any, index: any) => (
                                    <div
                                      key={index}
                                      className="input-group border mb-0 d-flex gap-1 rounded-2"
                                    >
                                      <Field
                                        type="email"
                                        className="form-control border-0 fs-12 p-0"
                                        placeholder="Enter email"
                                        value={email}
                                        onChange={(e: any) =>
                                          setFieldValue(
                                            `toCCBCCSettings.${setting}.other.${index}`,
                                            e.target.value
                                          )
                                        }
                                      />
                                      <span
                                        className="d-flex align-items-center"
                                        style={{
                                          color: "red",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => remove(index)}
                                      >
                                        <i className="ti ti-x" />
                                      </span>
                                    </div>
                                  )
                                )}
                                <div>
                                  <button
                                    type="button"
                                    style={{
                                      width: "30px",
                                    }}
                                    className="btn btn-primary btn-sm"
                                    onClick={() => push("")}
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                            )}
                          </FieldArray>
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => resetForm()}
                    id="btn-cancel"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {selectedReminderSetting
                      ? "Edit Remainder Settings"
                      : "Add Remainder Settings"}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default RemainderSettingsFormModal;
