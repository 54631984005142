import apiClient from "../../../api/apiClient";
import { LoginRequest, LoginResponse } from "./authTypes";

const BASE_PATH = "/system-admin";

export const login = async (
  credentials: LoginRequest
): Promise<LoginResponse> => {
  const response = await apiClient.post(`${BASE_PATH}/login`, credentials);
  return response.data;
};

export const currentUser = async (): Promise<LoginResponse> => {
  const response = await apiClient.get(`${BASE_PATH}/current-user`);
  return response.data;
};
