import React, { useEffect, useRef, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { all_routes } from "../../router/all_routes";
import { TableData } from "../../../core/data/interface";
import SchoolsGroupsFormModal from "./components/schools-groups-form-modal";
import Table from "../../../core/common/dataTable/index";
import { useAppDispatch, useAppSelector } from "../../../core/data/redux/hooks";

import { fetchSchoolGroupAsync } from "../../../core/data/redux/features/systemAdmin/systemAdminSlice";

const SchoolGroup = () => {
  const routes = all_routes;
  const dispatch = useAppDispatch();
  const [selectedschoolgroup, setselectedschoolgroup] =
    useState<TableData | null>(null);

  const { schoolGroupData, fetchLoading } = useAppSelector(
    (state) => state.systemAdmin
  );

  // console.log("Redux State:", { systemAdminData, fetchLoading, error });
  useEffect(() => {
    dispatch(fetchSchoolGroupAsync());
  }, [dispatch]);

  // useEffect(() => {
  //   console.log(systemAdminData, "systemAdminData");
  // }, [systemAdminData]);

  const columns = [
    {
      title: "School Group Name",
      dataIndex: "schoolGroupName",
      sorter: (a: TableData, b: TableData) =>
        a.schoolGroupName.length - b.schoolGroupName.length,
    },
    {
      title: "Contact Person Name",
      dataIndex: "contactPersonName",
      sorter: (a: TableData, b: TableData) =>
        a.contactPersonName.length - b.contactPersonName.length,
    },
    {
      title: "Contact Person PhoneNo",
      dataIndex: "contactPersonPhoneNo",
      sorter: (a: TableData, b: TableData) =>
        a.contactPersonPhoneNo.length - b.contactPersonPhoneNo.length,
    },
    {
      title: "Contact Person Designation",
      dataIndex: "contactPersonDesignation",
      sorter: (a: TableData, b: TableData) =>
        a.contactPersonDesignation.length - b.contactPersonDesignation.length,
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: TableData) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <div className="dropdown">
                <Link
                  to="#"
                  className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="ti ti-dots-vertical fs-14" />
                </Link>
                <ul className="dropdown-menu dropdown-menu-right p-3">
                  <li>
                    <div
                      className="dropdown-item rounded-1"
                      onClick={() => {
                        setselectedschoolgroup(record);
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#add_schoolgroup"
                    >
                      <i className="ti ti-edit-circle me-2" />
                      Edit
                    </div>
                  </li>
                  {/* <li>
                    <div
                      className="dropdown-item rounded-1"
                      onClick={() => {
                        setselectedschoolgroup(record);
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#delete-modal"
                    >
                      <i className="ti ti-trash-x me-2" />
                      Delete
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Schools Group</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <p>Dashboard</p>
                  </li>
                  <li className="breadcrumb-item">Schools</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    All Schools Group
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              {/* <TooltipOption /> */}

              <div className="mb-2">
                <div
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#add_schoolgroup"
                  onClick={() => {
                    setselectedschoolgroup(null);
                  }}
                >
                  <i className="ti ti-square-rounded-plus-filled me-2" />
                  Add School Group
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Students List */}
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">School Group List</h4>
              <div className="d-flex align-items-center flex-wrap"></div>
            </div>
            <div className="card-body p-0 py-3">
              {/* Student List */}
              <Table
                dataSource={schoolGroupData}
                columns={columns}
                loading={fetchLoading}
              />
              {/* /Student List */}
            </div>
          </div>
        </div>
      </div>
      {/* <SchoolModals /> */}
      <SchoolsGroupsFormModal selectedschoolgroup={selectedschoolgroup} />
    </>
  );
};

export default SchoolGroup;
