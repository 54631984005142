import { useEffect, useMemo, useRef } from "react";
import { Formik, ErrorMessage, Field } from "formik";
import CommonSelect from "../../../core/common/commonSelect";
import { useAppDispatch, useAppSelector } from "../../../core/data/redux/hooks";
import {
  createClassSectionAsync,
  fetchClassesAsync,
  fetchClassesSectionsAsync,
  updateClassSectionAsync,
} from "../../../core/data/redux/features/class/classSlice";
import { Class } from "../../../core/data/redux/features/class/classTypes";
import { createClassSectionInitialValues } from "./initialValue";
import { createClassSectionValidationSchema } from "./schema";

const ClassSectionFormModal = ({
  selectedClassSection,
  schoolData,
  groupData,
}: any) => {
  const classData = useAppSelector((state) => state.class.classData);
  // const schoolId = useAppSelector((state) => state.auth.user?.schoolId);
  const formRef = useRef(null);

  const classDataOption = classData.map((item: Class) => {
    return { value: item.id, label: item.className };
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (schoolData && !classData.length) {
      dispatch(
        fetchClassesAsync({
          schoolId: schoolData.id,
          schoolSubDomain: schoolData.schoolSubDomain,
          groupSubDomain: groupData?.groupSubDomain,
        })
      );
    }
  }, [schoolData]);

  useEffect(() => {
    const modalElement = document.getElementById("add_class_section");

    const handleModalClose = () => {
      if (formRef.current) {
        // @ts-ignore
        formRef.current.resetForm();
      }
    };

    modalElement?.addEventListener("hidden.bs.modal", handleModalClose);

    return () => {
      modalElement?.removeEventListener("hidden.bs.modal", handleModalClose);
    };
  }, []);

  const finalInitialValues = useMemo(() => {
    if (selectedClassSection) {
      return {
        classId: selectedClassSection?.id,
        sectionName: selectedClassSection?.sectionName,
        sectionDisplayName: selectedClassSection?.sectionDisplayName,
      };
    } else {
      return createClassSectionInitialValues;
    }
  }, [selectedClassSection]);

  // if (classData.length) {
  //   return null;
  // }

  return (
    <div className="modal fade" id="add_class_section">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              {selectedClassSection
                ? "Edit Class Section"
                : "Add Class Section"}
            </h4>
            <button
              type="button"
              className="btn-close custom-btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="ti ti-x" />
            </button>
          </div>
          <Formik
            initialValues={finalInitialValues}
            enableReinitialize
            innerRef={formRef}
            validationSchema={createClassSectionValidationSchema}
            onSubmit={async (values, actions) => {
              try {
                if (schoolData) {
                  let result: any;
                  if (selectedClassSection) {
                    result = await dispatch(
                      updateClassSectionAsync({
                        schoolId: schoolData.id,
                        classSectionId: selectedClassSection.classSectionId,
                        classSectionData: {
                          sectionName: values.sectionName,
                          sectionDisplayName: values.sectionDisplayName,
                        },
                        schoolSubDomain: schoolData.schoolSubDomain,
                        groupSubDomain: groupData?.groupSubDomain,
                      })
                    ).unwrap();
                  } else {
                    result = await dispatch(
                      createClassSectionAsync({
                        schoolId: schoolData.id,
                        newClassSection: values,
                        classId: values.classId,
                        schoolSubDomain: schoolData.schoolSubDomain,
                        groupSubDomain: groupData?.groupSubDomain,
                      })
                    ).unwrap();
                  }
                  const cancelButton = document.getElementById(
                    "btn-cancel"
                  ) as HTMLElement;
                  cancelButton.click();
                  await dispatch(
                    fetchClassesSectionsAsync({
                      schoolId: schoolData?.id,
                      section: 1,
                      schoolSubDomain: schoolData?.schoolSubDomain,
                      groupSubDomain: groupData?.groupSubDomain,
                    })
                  ).unwrap();
                }
              } catch (error) {
                console.error("Error creating class section:", error);
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              resetForm,
              isSubmitting,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label">Class</label>
                          <Field
                            className="select"
                            component={CommonSelect}
                            options={classDataOption}
                            name="classId"
                            //   defaultValue={classSection[0]}
                            disabled={!!selectedClassSection}
                          />
                        </div>
                        <ErrorMessage
                          name="classId"
                          className="text-danger"
                          component="div"
                        />
                        <div className="mb-3">
                          <label className="form-label">Section Name</label>
                          <Field
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.sectionName}
                            name="sectionName"
                          />
                        </div>
                        <ErrorMessage
                          name="sectionName"
                          className="text-danger"
                          component="div"
                        />

                        <div className="mb-3">
                          <label className="form-label">
                            Section Display Name
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.sectionDisplayName}
                            name="sectionDisplayName"
                          />
                        </div>
                        <ErrorMessage
                          name="sectionDisplayName"
                          className="text-danger"
                          component="div"
                        />

                        {/* <div className="mb-3">
                    <label className="form-label">No of Students</label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">No of Subjects</label>
                    <input type="text" className="form-control" />
                  </div> */}
                        {/* <div className="d-flex align-items-center justify-content-between">
                    <div className="status-title">
                      <h5>Status</h5>
                      <p>Change the Status by toggle </p>
                    </div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="switch-sm"
                      />
                    </div>
                  </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <div
                      onClick={() => resetForm()}
                      id="btn-cancel"
                      className="btn btn-light me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isSubmitting}
                    >
                      {selectedClassSection
                        ? "Edit Class Section"
                        : "Add Class Section"}
                    </button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default ClassSectionFormModal;
