export const createSchoolGroupInitialValues = {
  schoolGroupName: "",
  schoolGroupDisplayName: "",
  schoolGroupAddress: "",
  contactPersonName: "",
  contactPersonPhoneNo: "",
  contactPersonDesignation: "",
  groupSubDomain: "",
  groupLogo: "",
};
