import * as Yup from "yup";
import "yup-phone-lite";

const commonPhoneSchema = (isMandatory: Boolean, message?: string) =>
  Yup.string()
    .nullable()
    .when([], {
      is: () => isMandatory,
      then: (schema) =>
        schema
          .required(message || "Phone Number is required")
          .phone("*" as any, "Please enter a valid Phone Number"),
      otherwise: (schema) =>
        schema.test({
          name: "phoneValidation",
          skipAbsent: true,
          test(value, ctx) {
            if (!value) return true;
            if (!value || !value.includes(" ")) return true;
            const spaceIndex = value.indexOf(" ");
            let splitValue = value.slice(spaceIndex + 1);
            if (!splitValue) return true;

            const isValid = schema
              .phone("*" as any, "Please enter a valid Phone Number")
              .isValidSync(value);

            if (!isValid) {
              return ctx.createError({
                message: "Please enter a valid Phone Number",
              });
            }

            return true;
          },
        }),
    });
export const createSchoolsValidationSchema = Yup.object({
  schoolName: Yup.string().required("School Name is required"),
  schoolDisplayName: Yup.string().required("School Display Name is required"),
  schoolLogo: Yup.string().required("School Logo is required"),
  schoolAddress: Yup.string().required("School Address is required"),
  schoolSubDomain: Yup.string()
    .matches(/^[a-zA-Z]*$/, "Only letters and hyphens are allowed")
    .required("School SubDomain is required"),
  contactPersonName: Yup.string().required("Person Name is required"),
  contactPersonPhoneNo: commonPhoneSchema(
    true,
    "Primary Contact Number is required"
  ),
  contactPersonDesignation: Yup.string().required("Designation is required"),
});
