import * as Yup from "yup";
import "yup-phone-lite";

export const createReminderSettingsSchema = Yup.object({
  reminderTypeId: Yup.number().nullable().required("Reminder Type ID is required"),
  toCCBCCSettings: Yup.object({
    toSetting: Yup.object({
      student: Yup.boolean(),
      parent: Yup.boolean(),
      teacher: Yup.boolean(),
      schoolAdmin: Yup.boolean(),
      other: Yup.array().of(Yup.string().email("Invalid email")),
    }),
    ccSetting: Yup.object({
      student: Yup.boolean(),
      parent: Yup.boolean(),
      teacher: Yup.boolean(),
      schoolAdmin: Yup.boolean(),
      other: Yup.array().of(Yup.string().email("Invalid email")),
    }),
    bccSetting: Yup.object({
      student: Yup.boolean(),
      parent: Yup.boolean(),
      teacher: Yup.boolean(),
      schoolAdmin: Yup.boolean(),
      other: Yup.array().of(Yup.string().email("Invalid email")),
    }),
  }),
  frequency: Yup.array().of(Yup.number()).required("Frequency is required"),
  isActive: Yup.boolean().required("Active status is required"),
});