import { AxiosInstance } from "axios";

// Request interceptor
export const attachRequestInterceptor = (client: AxiosInstance): void => {
  client.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("authToken");
      // const host = window.location.hostname;
      // const subdomain = host.split(".")[0];
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      // config.headers.Subdomain = subdomain;
      // config.headers.Subdomain = "sgs-dev";

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

// Response interceptor
export const attachResponseInterceptor = (client: AxiosInstance): void => {
  client.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        localStorage.removeItem("authToken");
        window.location.href = "/login";
      }
      return Promise.reject(error);
    }
  );
};
