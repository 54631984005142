import {
  FeeState,
  Fee,
  FeeCreate,
  FeeUpdate,
  StudentFeeRecord,
  StudentFeePayment,
  StudentFeeRecordUpdate,
  StudentFeePaymentData,
  Feediscountandcategory,
  CollectFeesInitialValue,
  FeeDiscountAndCategoryQueryCriteria,
} from "./feeType";
import apiClient from "../../../api/apiClient";

//Base URL

const BASE_PATH = "/fee";

export const fetchFeesByCriteria = async (
  schoolId: number,
  criteria: {
    academicYearId?: number;
    classId?: number;
    feeCategoryId?: number;
    feeCategoryFrequencyId?: number;
  },
  schoolSubDomain: string,
  groupSubDomain: string
): Promise<{ data: Fee[]; message: string }> => {
  const queryParams = new URLSearchParams();

  Object.entries(criteria).forEach(([key, value]) => {
    if (value) {
      queryParams.append(key, value.toString());
    }
  });

  const response = await apiClient.get(
    `${BASE_PATH}/get-fees-by-criteria/${schoolId}?${queryParams.toString()}`,
    {
      headers: {
        Subdomain: schoolSubDomain || "",
        GroupSubDomain: groupSubDomain,
      },
    }
  );

  return response?.data;
};

export const createFee = async (
  schoolId: number,
  newFee: FeeCreate,
  schoolSubDomain: string,
  groupSubDomain: string
): Promise<{ data: Fee; message: string }> => {
  const response = await apiClient.post(
    `${BASE_PATH}/add-fee-by-criteria/${schoolId}`,
    newFee,
    {
      headers: {
        Subdomain: schoolSubDomain || "",
        GroupSubDomain: groupSubDomain || "",
      },
    }
  );
  return response?.data;
};

export const updateFee = async (
  schoolId: number,
  feeId: number,
  updatedFee: FeeUpdate,
  schoolSubDomain: string,
  groupSubDomain: string
): Promise<{ data: Fee; message: string }> => {
  const response = await apiClient.put(
    `${BASE_PATH}/update-fee-by-criteria/${schoolId}/${feeId}`,
    updatedFee,
    {
      headers: {
        Subdomain: schoolSubDomain || "",
        GroupSubDomain: groupSubDomain || "",
      },
    }
  );
  return response?.data;
};

export const deleteFee = async (
  schoolId: number,
  feeId: number,
  schoolSubDomain: string,
  groupSubDomain: string
): Promise<void> => {
  await apiClient.delete(
    `${BASE_PATH}/delete-fee-by-criteria/${schoolId}/${feeId}`,
    {
      headers: {
        Subdomain: schoolSubDomain || "",
        GroupSubDomain: groupSubDomain || "",
      },
    }
  );
};

export const manageStudentFee = async (
  schoolId: number,
  studentFeeData: {
    userId: number;
    feesData: Array<{
      feeId: number;
      paymentStatusId: number;
      discountPercentage: number;
      discountTypeId: number;
      discountRemarks: string;
    }>;
  },
  schoolSubDomain: string,
  groupSubDomain: string
): Promise<{ data: StudentFeeRecord; message: string }> => {
  const response = await apiClient.post(
    `${BASE_PATH}/manage-student-fee/${schoolId}`,
    studentFeeData,
    {
      headers: {
        Subdomain: schoolSubDomain || "",
        GroupSubDomain: groupSubDomain || "",
      },
    }
  );
  return response?.data;
};

export const fetchStudentFeeRecords = async (
  studentId: number,
  studentClassSectionId: number,
  schoolSubDomain: string,
  groupSubDomain: string
): Promise<{ data: StudentFeeRecord[]; message: string }> => {
  const response = await apiClient.get(
    `${BASE_PATH}/get-student-fee-record/${studentId}/${studentClassSectionId}`,
    {
      headers: {
        Subdomain: schoolSubDomain || "",
        GroupSubDomain: groupSubDomain || "",
      },
    }
  );
  return response?.data;
};

export const addStudentFeePayment = async (
  schoolId: number,
  studentId: number,
  paymentData: CollectFeesInitialValue,
  schoolSubDomain: string,
  groupSubDomain: string
): Promise<{ data: StudentFeePayment; message: string }> => {
  const response = await apiClient.post(
    `${BASE_PATH}/add-student-fee-payment/${schoolId}/${studentId}`,
    paymentData,
    {
      headers: {
        Subdomain: schoolSubDomain || "",
        GroupSubDomain: groupSubDomain || "",
      },
    }
  );
  return response?.data;
};

export const fetchStudentFeePayments = async ({
  schoolId,
  academicYearId,
  userId,
  schoolSubDomain,
  groupSubDomain,
}: {
  schoolId: number;
  academicYearId: number;
  userId?: number | null;
  schoolSubDomain: string;
  groupSubDomain: string;
}): Promise<{ data: StudentFeePayment[]; message: string }> => {
  const params = new URLSearchParams();
  params.append("academicYearId", academicYearId.toString());
  if (userId) {
    params.append("userId", userId.toString());
  }

  const response = await apiClient.get(
    `${BASE_PATH}/get-student-fee-payments-by-criteria/${schoolId}${
      params.toString() ? `?${params.toString()}` : ""
    }`,
    {
      headers: {
        Subdomain: schoolSubDomain || "",
        GroupSubDomain: groupSubDomain || "",
      },
    }
  );
  return response?.data;
};

export const updateStudentFeeRecord = async (
  studentFeeRecordId: number,
  updateData: {
    discountDetails: {
      discountSchoolMappingId: number;
      discountPercentage: number;
    }[];
  },
  schoolSubDomain: string,
  groupSubDomain: string
): Promise<{ data: StudentFeeRecord; message: string }> => {
  const response = await apiClient.put(
    `${BASE_PATH}/update-student-fee-record/${studentFeeRecordId}`,
    updateData,
    {
      headers: {
        Subdomain: schoolSubDomain || "",
      },
    }
  );
  return response?.data;
};

export const fetchFeediscountandcategory = async (
  schoolId: number,
  criteria: FeeDiscountAndCategoryQueryCriteria,
  schoolSubDomain: string,
  groupSubDomain: string
): Promise<{ data: Feediscountandcategory; message: string }> => {
  const params = new URLSearchParams({
    academicYearId: criteria.academicYearId.toString(),
    classId: criteria.classId.toString(),
  });
  const response = await apiClient.get(
    `${BASE_PATH}/get-fees-discount-and-categories-by-criteria/${schoolId}?${params}`,
    {
      headers: {
        Subdomain: schoolSubDomain,
        GroupSubDomain: groupSubDomain,
      },
    }
  );
  return response?.data;
};
