import { useState } from "react";
import { Formik, ErrorMessage, Field } from "formik";
import { useEffect, useMemo, useRef } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../core/data/redux/hooks";
import { createSchoolsInitialValues } from "../initialValues";
import { createSchoolsValidationSchema } from "../schema";
import PhoneInput from "react-phone-number-input";
import FeatherIcon from "feather-icons-react";
import UploadButton from "../../../../core/common/UploadButton";
import { errorToast } from "../../../../core/common/toast/toast";
import {
  createSchoolInGroupAsync,
  updateSchoolInGroupAsync,
  uploadMultipleFilesAsync,
  fetchSchoolByGroupIdAsync,
} from "../../../../core/data/redux/features/systemAdmin/systemAdminSlice";

const SchoolsFormModal = ({ selectedschool, groupId }: any) => {
  console.log(selectedschool, "selectedschoolgroup");
  const dispatch = useAppDispatch();
  const formRef = useRef(null);
  useEffect(() => {
    const modalElement = document.getElementById("add_school");

    const handleModalClose = () => {
      if (formRef.current) {
        // @ts-ignore
        formRef.current.resetForm();
      }
    };

    modalElement?.addEventListener("hidden.bs.modal", handleModalClose);

    return () => {
      modalElement?.removeEventListener("hidden.bs.modal", handleModalClose);
    };
  }, []);

  const finalInitialValues = useMemo(() => {
    if (selectedschool) {
      return {
        schoolName: selectedschool.schoolName,
        schoolDisplayName: selectedschool.schoolDisplayName,
        schoolSubDomain: selectedschool.schoolSubDomain,
        schoolLogo: selectedschool.schoolLogo,
        schoolAddress: selectedschool.schoolAddress,
        contactPersonName: selectedschool.contactPersonName,
        contactPersonPhoneNo: selectedschool.contactPersonPhoneNo,
        contactPersonDesignation: selectedschool.contactPersonDesignation,
      };
    }
    return createSchoolsInitialValues;
  }, [selectedschool]);

  const handelfileupload = async (
    file: any,
    name: string,
    setFieldValue: any
  ) => {
    if (file) {
      const formData = new FormData();
      formData.append("files", file);

      try {
        const response = await dispatch(
          uploadMultipleFilesAsync({
            formData,
            fileCategory: "schoolLogo",
          })
        ).unwrap();

        setFieldValue(name, response.uploadedFiles[0].fileUrl);
      } catch (error) {
        errorToast("Upload failed:");
      }
    }
  };

  return (
    <div className="modal fade" id="add_school">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              {selectedschool ? "Edit School" : "Add School"}
            </h4>
            <button
              type="button"
              className="btn-close custom-btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="ti ti-x" />
            </button>
          </div>
          <Formik
            initialValues={finalInitialValues}
            enableReinitialize
            innerRef={formRef}
            validationSchema={createSchoolsValidationSchema}
            onSubmit={async (values, actions) => {
              console.log("Form Values Before Submitting:", values);
              try {
                if (groupId) {
                  // let result;
                  if (selectedschool) {
                    const result = await dispatch(
                      updateSchoolInGroupAsync({
                        groupId: groupId,
                        id: selectedschool.id,
                        updatedSchoolGroup: values,
                      })
                    ).unwrap();
                    if(result){
                      document.getElementById("btn-cancel")?.click();
                      await dispatch(fetchSchoolByGroupIdAsync(groupId)).unwrap();
                    }
                    return null;
                  } else {
                    const result = await dispatch(
                      createSchoolInGroupAsync({
                        groupId: groupId,
                        newSchoolInGroup: values,
                      })
                    ).unwrap();
                    if(result){
                      document.getElementById("btn-cancel")?.click();
                      await dispatch(fetchSchoolByGroupIdAsync(groupId)).unwrap();
                    }
                  }
                  
                }
              } catch (error) {
                console.error("Error creating academic year:", error);
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              setFieldTouched,
              values,
              resetForm,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">School Name</label>
                        <Field
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.schoolName}
                          name="schoolName"
                        />
                        <ErrorMessage
                          name="schoolName"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          School Display Name
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.schoolDisplayName}
                          name="schoolDisplayName"
                        />
                        <ErrorMessage
                          name="schoolDisplayName"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">School Subdomain</label>
                        <Field
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.schoolSubDomain}
                          name="schoolSubDomain"
                        />
                        <ErrorMessage
                          name="schoolSubDomain"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="col-xxl col-xl-3 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">School Logo</label>
                        <Field
                          name="schoolLogo"
                          component={UploadButton}
                          className="form-control"
                          buttonClassName="btn btn-primary btn-sm"
                          // buttonText="Upload School Logo"
                          image={values.schoolLogo}
                          multiple={false}
                          onFileUpload={(file: any) =>
                            handelfileupload(file, "schoolLogo", setFieldValue)
                          }
                          initialFileNames={
                            values.schoolLogo ? ["School Logo"] : []
                          }
                          accept=".pdf,.png,.jpg,.jpeg"
                        />
                        <ErrorMessage
                          name="schoolLogo"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">School Address</label>
                        <Field
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.schoolAddress}
                          name="schoolAddress"
                        />
                        <ErrorMessage
                          name="schoolAddress"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">School Person Name</label>
                        <Field
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.contactPersonName}
                          name="contactPersonName"
                        />
                        <ErrorMessage
                          name="contactPersonName"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Contact Person Phone Number
                        </label>
                        <PhoneInput
                          placeholder="Enter phone number"
                          className="form-control"
                          value={values.contactPersonPhoneNo}
                          name="contactPersonPhoneNo"
                          onChange={(value) =>
                            setFieldValue("contactPersonPhoneNo", value)
                          }
                        />
                        <ErrorMessage
                          name="contactPersonPhoneNo"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Contact Person Designation
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.contactPersonDesignation}
                          name="contactPersonDesignation"
                        />
                        <ErrorMessage
                          name="contactPersonDesignation"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => resetForm()}
                    id="btn-cancel"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isSubmitting}
                  >
                    {selectedschool ? "Edit School" : "Add School"}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default SchoolsFormModal;
